import React from 'react'
import './topbar.css';
import { useTranslation } from "react-i18next";


const Topbar = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="container-fluid top-section text-white">
        <div className="top-icon d-flex align-items-center ps-3">
          <i className="bi bi-telephone-fill ml-2"></i>
          <p className="ml-2 mb-0 ltr">+966552668383</p>
          <i className="bi bi-geo-alt-fill ml-2"></i>
          <p className="ml-2 mb-0">{t('topbar')}</p>
        </div>
        <div className="social-media d-flex align-items-center pe-4">
          <h6 className="mr-2">{t('follow')}:</h6>
          <a href="https://x.com/AqareAl42297" target='_blank'><i className="bi bi-twitter ml-2"></i></a> 
          <a href="https://www.facebook.com/profile.php?id=61567935840553" target='_blank'><i className="bi bi-facebook ml-2"></i></a>
          <a href="https://www.tiktok.com/@aqare.saa" target='_blank'><i className="bi bi-tiktok ml-2"></i></a>
          <a href="https://www.instagram.com/aqare.saa/" target='_blank'><i className="bi bi-instagram ml-2"></i></a>
        </div>
      </div>
    </div>
  )
}

export default Topbar
