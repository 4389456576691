import React, { useState ,useEffect} from "react";
import CustomNavbar from "../components/navBar/NavBar";
import Footer from "../components/Footer/Footer";
import { Carousel } from "react-bootstrap";
import { NavLink , useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import Topbar from "../components/Top Bar/Topbar";
import PageLoader from "../components/PageLoader";
import ApiService from '../services/ApiService';
import Calculator from "../components/Calculator/Calculator"

import {
  Container,
  Row,
  Col,
} from "react-bootstrap";

const PropertyDetails = () => {
  const { t } = useTranslation();
  const app_lang=localStorage.getItem('lang') || 'en';
  const user= JSON.parse(localStorage.getItem('user'));

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isPageLoader, setPageLoader] = useState(false);
  const handleSelect = (selectedIndex) => {
    setSelectedIndex(selectedIndex);
  };
  const { slug } = useParams(); 
  const [property, setProperty] = useState([]);
  const [is_favourite, setIsFavourite] = useState(true);


  useEffect(() => {
    window.scrollTo(0, 0);
    getProperties();
  }, []);

  const getProperties = async () => {
    try {
      setPageLoader(true);
      const response = await ApiService.request({
        method: 'GET',
        url: `getPropertyDetails`, // Replace with your API endpoint
        params:{property_id: slug}
      });
      const data = response.data;
      if (data.status) {
        setProperty(data.data);
        setIsFavourite(data.data.is_favourite);
      } else {
        toast.error(data.message);
      }
      setPageLoader(false);
    } catch (error) {
      setPageLoader(false);
    }
  };

  const addRemoveFavourite = async () => {
    if(!user){
        toast.error('You need to login first!');
        return;
    }
    try {
      const response = await ApiService.request({
        method: 'POST',
        url: `addRemoveFavourite`, // Replace with your API endpoint
        params: {property_id:property.id}
      });
      const data = response.data;
      if (data.status) {
        if (data.data== 'added') {
            setIsFavourite(true);
        }else{
            setIsFavourite(false);
        }
        
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
};

  const truncateString = (str, num = 10) => {
    return str.length > num ? str.substring(0, num) + '...' : str;
  };


  return (
    <div className="main-property">
      {isPageLoader ? (
        <PageLoader />
      ):(
        
      <div className="property-details-body">
        {/* Top Bar */}
        <Topbar />
        {/* Navbar */}
        <CustomNavbar />

        <div className="estate-header">
          <div className="header-txt text-white">
            <p>
              <NavLink to="/" className="nav-link d-inline">  {t('home')}  </NavLink>  
              <i className={`bi ${app_lang === 'en' ? 'bi-chevron-right' : 'bi-chevron-left'}`}></i>
              <NavLink to="/properties" className="nav-link d-inline"> {t('real_estate')}   </NavLink> 
              <i className={`bi ${app_lang === 'en' ? 'bi-chevron-right' : 'bi-chevron-left'}`}></i> {property.title}
            </p>
          </div>
        </div>
        <div className="container mb-5">
          <div className="row">
            <div className="col-md-8">
              <div className="badge-main d-flex mt-5">
                <div className="badge1">
                  <span> {property.list_type}</span>
                </div>
                <div className="badge2 mx-2">
                  <span>{property.condition}</span>
                </div>
              </div>
              <div className="heading my-4">
                <h1>{property.title}</h1>
              </div>
              <div className="property-info">
                {/* <i className="bi bi-arrow-repeat"></i> */}
                <i className="bi bi-geo-alt-fill mx-2"></i>
                <span>
                  {property.address}
                  <span className="mx-2">/</span>
                </span>
                <i className="bi bi-clock mx-1"></i>
                <span>
                  {moment(property.created_at).fromNow()} <span className="mx-2">/</span>
                </span>
                <i className="bi bi-eye"></i> <span>{t('views')} : {property.views}</span>
              </div>

              {property.media_files  &&
                <div className="mt-4">
                  <Carousel
                    activeIndex={selectedIndex}
                    onSelect={handleSelect}
                    className="main-carousel"
                  >
                      {property.media_files.map((file,index) => (
                       file.type.includes("image") &&
                        <Carousel.Item key={index}>
                          <img
                            className="d-block w-100"
                            src={file.file}
                            alt="property img"
                          />
                        </Carousel.Item>
                      ))}
                  </Carousel>

                  <div className="thumbnail-container d-flex justify-content-center mt-3">
                      {property.media_files.map((file, index) => (
                        file.type.includes("image") &&
                        <img key={index}
                          src={file.file}
                          alt="Thumbnail"
                          className={`rounded thumbnail ${
                            selectedIndex === index ? "selected" : ""
                          }`}
                          onClick={() => handleSelect(index)}
                        />
                      ))}
                  </div>
                </div>
              }

              <div className="more-info-card mt-5 rounded shadow">
                <h2>{t('overview')} </h2>

                <div className="container">
                  <div className="row info-tab1 mt-4">
                    <div className="col-6 col-md-3 mb-5">
                      <i className="bi bi-tag shadow"></i>
                      <div className="id-num">
                        <p>{t('id_no')}</p>
                        <span>{property.id}</span>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 mb-5">
                      <i className="bi bi-house-gear shadow"></i>
                      <div className="id-num">
                        <p>{t('category')}</p>
                        <span>{property?.category?.name}</span>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 mb-5">
                      <i className="bi bi-car-front shadow"></i>
                      <div className="id-num">
                        <p>{t('parking')}</p>
                        <span>{property.parking}</span>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 mb-5">
                      <i className="bi bi-house shadow"></i>
                      <div className="id-num">
                        <p>{t('bedrooms')}</p>
                        <span>{property.bedrooms}</span>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 mb-5">
                      <i className="bi bi-house-door shadow"></i>
                      <div className="id-num">
                        <p>{t('bathrooms')}</p>
                        <span>{property.bathrooms}</span>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 mb-5">
                      <i className="bi bi-arrows-fullscreen shadow"></i>
                      <div className="id-num">
                        <p>{t('area')}</p>
                        <span>{property.area}</span>
                      </div>
                    </div>

                    <div className="col-6 col-md-3 mb-5">
                      <i className="bi bi-braces-asterisk shadow"></i>
                      <div className="id-num">
                        <p>{t('list_type')}</p>
                        <span>{property.list_type}</span>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 mb-5">
                      <i className="bi bi-geo-alt-fill shadow"></i>
                      <div className="id-num">
                        <p>{t('address')}</p>
                        {property.address  && 
                          <span>{truncateString(property.address)}</span>
                        }
                      </div>
                    </div>
                    <div className="col-6 col-md-3 mb-5">
                      <i className="bi bi-house-door shadow"></i>
                      <div className="id-num">
                        <p>{t('construction_condition')}</p>
                        <span>{property.condition}</span>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 mb-5">
                      <i className="bi bi-house-door shadow"></i>
                      <div className="id-num">
                        <p>{t('construction_year')}</p>
                        <span>{property.construction_year}</span>
                      </div>
                    </div>

                  </div>

                </div>

                <hr className="hr mt-2" />
                <h3 className="my-4 this_list">{t('about_list')}</h3>
                <p dangerouslySetInnerHTML={{ __html: property.description }}></p>
                {property.video_files && property.video_files.map((video, index) => (
                  <video width="100%" controls>
                    <source src={video.file} type="video/mp4" />
                      Your browser does not support the video tag.
                  </video>
                ))}
                <hr className="hr" />

                <Container className="my-5">
                  <h3 className="text-right">{t('features_amenities')}</h3>
                  {property.features &&
                  <Row className="mt-4">
                    {property.features.map((feature, index) => (
                      <Col  xs={6}  md={4}
                        className="d-flex flex-column align-items-start"  key={index}
                        >
                        <div className="d-flex align-items-center mb-3">
                          <i className="bi bi-check-circle-fill icon-col me-2"></i>
                          <span className="check-txt">{feature}</span>
                        </div>
                      </Col>
                    ))}
                  </Row>
                  }
                </Container>

                <hr />
                <div
                  style={{ width: "100%", height: "500px", overflow: "hidden" }}
                >
                  <h2 className="my-3">{t('site_map')}</h2>
                  <iframe
                    src={`https://www.google.com/maps?q=${property.lat},${property.lng}&hl=es;z=14&output=embed`}
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title={property.address}
                  ></iframe>
                </div>
                <hr />
                {/* <h6 className="left-text">There are no reviews yet.</h6>
                <hr /> */}

                {/*Review  Form */}
                {/* <Form className="mt-5">
                  <h5 className="left-txt">Be the first to review "meadows"</h5>
                  <Form.Group controlId="reviewTitle" className="mt-3">
                    <Form.Label className="left-txt">Review title *</Form.Label>
                    <Form.Control type="text" placeholder="Enter title" />
                  </Form.Group>
              
                  <Form.Group controlId="reviewText" className="mt-3">
                    <Form.Label className="left-txt">Your review *</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Write your review here..."
                    />
                  </Form.Group>
                  <Row className="mt-3">
                    <Col>
                      <Form.Group controlId="name">
                        <Form.Label className="left-txt">Name *</Form.Label>
                        <Form.Control type="text" className="py-3" />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="email">
                        <Form.Label className="left-txt">Email *</Form.Label>
                        <Form.Control type="email" className="py-3" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group controlId="saveInfo" className="mt-3">
                    <Form.Check
                      type="checkbox"
                      className="left-txt"
                      label="Save my name, email, and website in this browser for the next time I comment."
                    />
                  </Form.Group>
                  <Button type="submit" className="mt-3 details-btn ">
                    Submit Now
                  </Button>
                </Form> */}
              </div>

                
            </div>

            <div className="col-md-4 mt-5">
              <div className="main-right">
                
                {property.pricing!=='disabled' && 
                <div>
                    <h4>{t('currency')} {property.price} - {t('currency')} {property.max_price}</h4>
                    <p>({property.price_type})</p>
                </div>
                }

                <div className="d-flex justify-content-flex-end align-items-center mt-5 icons-ac">
                  {/* Action Buttons */}
                  <div className="actoins-icons">
                    {/* <i className="bi bi-bug "></i> */}
                    <i className="bi bi-share"></i>
                    <i className={`bi ${is_favourite ? 'bi-heart-fill' : 'bi-heart'}`}  onClick={() => addRemoveFavourite()}></i>
                    <i className="bi bi-printer" onClick={() => window.print()} ></i>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body">
                    <h4 className="fw-normal">{t('contact_listing')}</h4>
                    <div className="d-flex align-items-center mb-3">
                      <img
                        src={property?.agency?.logo}
                        alt="Owner"
                        className="rounded-circle mr-3"
                      />
                      <div className="right-form">
                        <h5 className="fw-light my-3">{property.agency?.name}</h5> 
                        <p className="m-0">
                          <i className="bi bi-telephone-fill me-2"></i>{property.phone}  ({t('show')})
                        </p>
                        <p className="m-0">
                          <i className="bi bi-whatsapp text-success me-2"></i>
                          {property.whatsapp} ({t('show')})
                        </p>
                      </div>
                    </div>

                    {/* Contact Form */}
                    <form>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder={t('name')}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder={t('email')}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="tel"
                          className="form-control"
                          id="phone"
                          placeholder={t('phone')}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          id="message"
                          rows="3"
                          placeholder={t('message')}
                          required
                        ></textarea>
                      </div>
                      <button
                        type="submit"
                        className="sendButton btn-block shadow rounded"
                      >
                        {t('send_message')}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="second-right rounded p-3">
                {/* <h3>Recently added</h3> */}
                {/* <img src={test_property} alt="" className="rounded" />
                <div className="details-props">
                  <p className="text-below-image">chalet</p>
                  <h5>Test Property</h5>
                  <p className="location">
                    <i className="bi bi-geo-alt"></i>
                    <span>Riyadh Region, Saudi Arabia</span>
                  </p>
                  <span>Rs 200 (Negotiable)</span>
                </div> */}
              </div>
                <Calculator />

            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
      
     )}
    
    </div>
  );
};

export default PropertyDetails;
