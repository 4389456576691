import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Calculator = () => {
  const { t } = useTranslation();

  const [loanAmount, setLoanAmount] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [years, setYears] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [monthlyPayment, setMonthlyPayment] = useState(null);
  const resetForm = () => {
    setLoanAmount("");
    setDownPayment("");
    setYears("");
    setInterestRate("");
  };
  const calculateLoan = (e) => {
    e.preventDefault();

    const principal = loanAmount - loanAmount * (downPayment / 100);
    const monthlyInterestRate = interestRate / 100 / 12;
    const numberOfPayments = years * 12;

    const payment =
      (principal * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));

    setMonthlyPayment(payment.toFixed(2));
  };

  return (
    <div>
      <div className="third-right mt-5 p-3 shadow">
              <div className="calculate-form">
                <h3 className="text-center mb-4">{t("calc")}</h3>
                <form onSubmit={calculateLoan} >
                  <div className="form-group mb-3">
                    <label>{t("loan_amount")}</label>
                    <input
                      type="number"
                      className="form-control"
                      value={loanAmount}
                      onChange={(e) => setLoanAmount(e.target.value)}
                      placeholder="100000"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label>{t("down_payment")}</label>
                    <input
                      type="number"
                      className="form-control"
                      value={downPayment}
                      onChange={(e) => setDownPayment(e.target.value)}
                      placeholder="5%"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label>{t("years")}:</label>
                    <input
                      type="number"
                      className="form-control"
                      value={years}
                      onChange={(e) => setYears(e.target.value)}
                      placeholder="12 Years"
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label>{t("interest_rate")}:</label>
                    <input
                      type="number"
                      className="form-control"
                      value={interestRate}
                      onChange={(e) => setInterestRate(e.target.value)}
                      placeholder="10%"
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <button type="submit" className="mt-3 btn btn-base py-2">
                      {t("calculator")}
                    </button>
                    <button
                      type="button"
                      className="reset-btn btn btn-outline-dark  mt-3 py-2"
                      onClick={resetForm}
                    >
                      {t("reset_form")} <i class="bi bi-arrow-repeat "></i>
                    </button>
                  </div>
                </form>
                {monthlyPayment && (
                  <div className="mt-4">
                    <h5>
                      {t("monthly_payment")}: ${monthlyPayment}
                    </h5>
                  </div>
                )}
              </div>
            </div>
    </div>
  );
};

export default Calculator;
