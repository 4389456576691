// UserDashboard.js
import React, { useState,useEffect } from "react";
import { useLocation } from "react-router-dom";
import Topbar from "../../components/Top Bar/Topbar";
import NavBar from "../../components/navBar/NavBar";
import Footer from "../../components/Footer/Footer";
import UserSidebar from "../../components/UserSidebar/UserSidebar";
import { Card, Table } from "react-bootstrap";
import Agency from "../User/Agency/Agency";
import DashboardPage from "./DashboardPage";

import ListProperty from '../User/Property/ListProperty';
import PropertyForm from '../User/Property/PropertyForm';
import ListFavourite from '../User/Favourite/ListFavourite';
import ChatBox from '../User/Chat/ChatBox';
import ListPayment from '../User/Payment/ListPayment';
import Account from '../User/Account';
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const [activeComponent, setActiveComponent] = useState("Dashboard");
  const user= JSON.parse(localStorage.getItem('user'));
  const { t } = useTranslation();

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const tab = urlParams.get("tab") ?? '';
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Topbar />
      <NavBar />
      <div className="container">
        <div className="row">
          <div className="col-md-3 dashboard-left-side">
            <UserSidebar setActiveComponent={setActiveComponent} />
          </div>
          <div className="col-md-9">
            <div className="right-bar mt-5">
             
              {tab === "" && <DashboardPage />}


              {tab === "properties" && <ListProperty />}

              {tab === "add-property" && <PropertyForm />}

              {tab === "agency" && <Agency />}

              {tab === "favourites" && <ListFavourite />}

              {tab === "chat" && <ChatBox />}
              {tab === "account" && <Account />}

              {tab === "payments" && <ListPayment />}

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;
