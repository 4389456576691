import React, { useState, useEffect } from "react";
import CustomNavbar from "../components/navBar/NavBar";
import Footer from "../components/Footer/Footer";
import {  useParams } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Topbar from "../components/Top Bar/Topbar";
import PageLoader from "../components/PageLoader";
import ApiService from '../services/ApiService';
import { toast } from "react-toastify";

import {
  Carousel,
  Container,
  Row,
  Col,
  Button,
  Tabs,
  Tab,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import CountdownTimer from "../components/CountdownTimer/CountdownTimer";

const PropertyDetails = () => {
  const { t } = useTranslation();
  const app_lang=localStorage.getItem('lang') || 'en';


  const [isPageLoader, setPageLoader] = useState(false);
  const [auction, setAuction] = useState({});
  const [bids, setBids] = useState([]);
  const [alreadyBid, setAlreadyBid] = useState(false);
  const [amount, setAmount] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };
  const { id } = useParams(); 

  useEffect(() => {
    window.scrollTo(0, 0);
    getAuctionDetails();
  }, []);

  const getAuctionDetails = async () => {
    try {
      setPageLoader(true);
      const response = await ApiService.request({
        method: 'GET',
        url: `getAuctionDetails`, // Replace with your API endpoint
        params:{auction_id:id}
      });
      const data = response.data;
      if (data.status) {
        setAuction(data.data.auctions);
        setBids(data.data.bids);
        setAlreadyBid(data.data.alreadyBid);
      } else {
        toast.error(data.message);
      }
      setPageLoader(false);
    } catch (error) {
      setPageLoader(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await ApiService.request({
        method: 'POST',
        url: `bidAuction`, // Replace with your API endpoint
        data: {auction_id: id, amount:amount}
      });
      const data = response.data;
      if (data.status) {
        toast.success(data.message);
        setAmount('');
        getAuctionDetails();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const targetTimestamp = new Date(auction.end_date).getTime();

  return (
    <div>
      {isPageLoader ? (
        <PageLoader />
      ):(
      <div>
        <Topbar />
        <CustomNavbar />

        <div className="auctions-details container mt-5 bg-white pt-4">
          <h1>{t('auctions')}</h1>
          <Container className="d-flex justify-content-center align-items-start p-4">
            <Row className="w-100">
              <Col md={6}>
              
              {auction.media_files  &&
                <Carousel
                  activeIndex={currentIndex}
                  onSelect={(selectedIndex) => setCurrentIndex(selectedIndex)}
                  >
                    {auction.media_files.map((file,index) => (
                      <Carousel.Item key={index}>
                        <img
                          className="d-block w-100"
                          src={file.file}
                          alt="auction-img"
                        />
                      </Carousel.Item>
                    ))}
                  
                </Carousel>
              }

                <Row className="mt-3 thumbnail-img">
                    {auction.media_files && auction.media_files.map((file, index) => (
                      <Col xs={4} onClick={() => handleThumbnailClick(index)}>

                        <img key={index}
                          src={file.file}
                          alt="Thumbnail"
                          className={`rounded img-thumbnail ${
                            currentIndex === index ? "selected" : ""
                          }`}
                          onClick={() => handleThumbnailClick(index)}
                        />
                     </Col>
                    ))}
                    
                </Row>
              </Col>

              <Col md={6} className=" img-detailed-info">
                <h3>{auction.title}</h3>
                <hr />
                <h4 className="text-dark">{t('starting_bid')}: {t('currency')} {auction.open_price}</h4>
                <h4 className={`bi ${app_lang === 'en' ? 'float-end' : 'float-start'}`}>{auction.bids_count} {t('bids')}</h4>
                <p className="text-muted">
                  {t('reserve_not_met')}.
                </p>
                <hr />
                <p className="font-weight-bold"> {t('ending_in')}:</p>

                <CountdownTimer targetTimestamp={targetTimestamp} />

                <p className="mt-3">
                {t('ending_on')}:
                  <div className="text-muted">{moment(auction.end_date).format('MMMM D, YYYY h:mm a')} </div>
                </p>
                <p>
                {t('timezone')}: <div className="text-muted">Asia/Riyadh</div>
                </p>
                <hr />
                <div className="container mt-4">
                  {!alreadyBid ? (
                    <form  onSubmit={handleSubmit} method="POST">
                      <label className="form-label">{t('bid_value')}:</label>
                      <InputGroup className="mb-3">
                        <FormControl
                        type="number" step={0.1}
                          required
                          placeholder="in riyal SAR"
                          aria-label="Currency Symbol"
                          style={{ maxWidth: "70%", textAlign: "center" }}
                          min={auction.open_price}
                          onChange={(e) => setAmount(e.target.value)}
                        />
                        <Button className="bid-btn" type="submit">{t('place_bid')}</Button>
                      </InputGroup>

                      <div className="text-muted mb-3">({t('enter')} {t('currency')} {auction.open_price} {t('or_more')})</div>

                      <Button variant="primary bid-buy-btn" type="button">
                        {t('buy_now')} : {t('currency')} {auction.buy_now_price}
                      </Button>
                    </form>
                    ):(
                      <p className="text-base">{t('already_bid')}</p>
                    )
                  }

                </div>
              </Col>
            </Row>
          </Container>
          <Container className="mt-4">
            <Tabs
              defaultActiveKey="description"
              id="property-tabs"
              className="mb-3"
            >
              <Tab eventKey="description" title={t('description')}>
                <div className="p-3">
                    <p dangerouslySetInnerHTML={{ __html: auction.description }}></p>
                </div>
              </Tab>

              {/* <Tab eventKey="privateMessage" title="Send Private Message">
                <div className="p-3">
                  <Form>
                    <Form.Group controlId="formName">
                      <Form.Label>Name:</Form.Label>
                      <Form.Control type="text" placeholder="Enter your name" />
                    </Form.Group>

                    <Form.Group controlId="formEmail" className="mt-3">
                      <Form.Label>Email:</Form.Label>
                      <Form.Control type="email" placeholder="Enter your email" />
                    </Form.Group>

                    <Form.Group controlId="formMessage" className="mt-3">
                      <Form.Label>Message:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter your message"
                      />
                    </Form.Group>

                    <Button type="submit" className="mt-4 auction-details-btn">
                      Send
                    </Button>
                  </Form>
                </div>
              </Tab> */}

              <Tab eventKey="totalBids" title={t('total_bids_placed')}>
                <div className="p-3">
                  <div className="table-responsive">
                    <table className="table ">
                      <thead>
                      <tr>
                        <th> {t('bidder_name')}</th>
                        <th> {t('bid_price')}</th>
                        <th> {t('when')}</th>
                      </tr>
                      </thead>
                      <tbody>
                        {bids.map((bid) => (
                          <tr key={bid.id}>
                            <td> {bid?.user?.username}</td>
                            <td> {t('currency')} {bid.amount} </td>
                            <td> {moment(bid.created_at).fromNow()}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {bids.length < 1 &&
                    <p> {t('no_bid_placed')} </p>
                  }
                </div>
              </Tab>
            </Tabs>
          </Container>
        </div>
        <Footer />
      </div>
      )}
    </div>

  );
};

export default PropertyDetails;
