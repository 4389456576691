import React, { useEffect, useState } from 'react';

const CountdownTimer = ({ targetTimestamp }) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetTimestamp));

    // Function to calculate the time left based on the target timestamp
    function calculateTimeLeft(targetTime) {
        const now = new Date().getTime();
        const timeDifference = targetTime - now;

        if (timeDifference <= 0) return null;

        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
    }

    useEffect(() => {
        const timer = setInterval(() => {
            const newTimeLeft = calculateTimeLeft(targetTimestamp);
            setTimeLeft(newTimeLeft);
            if (!newTimeLeft) clearInterval(timer); // Stop the timer when the countdown is complete
        }, 1000);

        // Clear interval on component unmount
        return () => clearInterval(timer);
    }, [targetTimestamp]);

    if (!timeLeft) return <div>Time's up!</div>;

    return (
        <div className='countdown-container'>
            <div className='countdown-headings'>
                <span>Days</span>
                <span>Hours</span>
                <span>Mins</span>
                <span>Sec</span>
            </div>
            <div className='countdown-values'>
                <span>{timeLeft.days}</span>
                <span>{timeLeft.hours}</span>
                <span>{timeLeft.minutes}</span>
                <span>{timeLeft.seconds}</span>
            </div>
        </div>
    );
};

export default CountdownTimer;