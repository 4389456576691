import React, {  useEffect } from "react";

import Topbar from "../components/Top Bar/Topbar";
import Footer from "../components/Footer/Footer";
import CustomNavbar from "../components/navBar/NavBar";
import { useTranslation } from "react-i18next";
import { NavLink  } from 'react-router-dom';


const RightsPolicy = () => {
  const { t } = useTranslation();
  const app_lang=localStorage.getItem('lang') || 'en';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Topbar />
      <CustomNavbar />
      
      <div className="estate-header">
        <div className="header-txt text-white">
          <p>
              <NavLink to="/" className="nav-link d-inline">  {t('home')} </NavLink>  
              <i className="bi bi-chevron-right"></i>{t('rights_policy')}
          </p>
        
        </div>
      </div>

      <div className="container mb-5">
        <div className="bg-white mt-5 p-5">
          <h4 className="fw-semibold">{t('rights_policy')}</h4>
          {app_lang === 'en'? (
            <div className="main-en">
              <h4 className="fw-normal">Introduction</h4>
              <p>
                  At Khutwat Al-Aqar Platform, we respect intellectual property rights and are committed to protecting and respecting the rights of third parties, as well as the rights of our users. This policy explains how we handle content protected by intellectual property laws.
              </p>

              <h4 className="fw-normal">Intellectual Property Protected Content</h4>
              <p>
                  Intellectual property-protected content includes, but is not limited to, texts, images, graphics, trademarks, logos, and any other materials appearing on our site. All rights to these materials are reserved to their original owners or to us, as the case may be.
              </p>

              <h4 className="fw-normal">Legal Use</h4>
              <p>
                  Personal and non-commercial use of the content available on the Khutwat Al-Aqar Platform is permitted only within the legally allowed limits. Any other use, including republishing, distribution, or copying, requires explicit permission from us or the respective intellectual property rights holders.
              </p>

              <h4 className="fw-normal">Restrictions</h4>
              <p>
                  The use of the Khutwat Al-Aqar Platform for any illegal purpose or to infringe upon third-party intellectual property rights is strictly prohibited. This includes, but is not limited to, unauthorized use of content, imitation, and illegal distribution.
              </p>

              <h4 className="fw-normal">Reporting Intellectual Property Violations</h4>
              <p>
                  If you believe that your work has been copied in a way that constitutes an infringement of intellectual property rights on the Khutwat Al-Aqar Platform, please notify us with the necessary information to support your claim. We will take appropriate action to address complaints in accordance with the law.
              </p>

              <h4 className="fw-normal">Amendments to the Intellectual Property Policy</h4>
              <p>
                  We reserve the right to modify this policy at any time. You are advised to review this policy regularly to ensure that you understand the current terms.
              </p>

              <h4 className="fw-normal">Contact Us</h4>
              <p>
                  For any questions or inquiries regarding the Intellectual Property Policy, please contact us at:
                  <a href="mailto:info@aqare.sa">info@aqare.sa</a> <br />
                  +966552668383
              </p>

            </div>
             ):(

              <div className="main-ar">
                <h4 className="fw-normal">المقدمة</h4>
                <p>
                    في منصة خطوات العقار، نحن نحترم حقوق الملكية الفكرية وملتزمون بحماية واحترام حقوق الأطراف الثالثة، بالإضافة إلى حقوق مستخدمينا. تشرح هذه السياسة كيفية تعاملنا مع المحتوى المحمي بقوانين الملكية الفكرية.
                </p>

                <h4 className="fw-normal">المحتوى المحمي بالملكية الفكرية</h4>
                <p>
                    يتضمن المحتوى المحمي بالملكية الفكرية، على سبيل المثال لا الحصر، النصوص والصور والرسوم البيانية والعلامات التجارية والشعارات وأي مواد أخرى تظهر على موقعنا. جميع الحقوق لهذه المواد محفوظة لأصحابها الأصليين أو لنا، حسب الحالة.
                </p>

                <h4 className="fw-normal">الاستخدام القانوني</h4>
                <p>
                    يُسمح باستخدام المحتوى المتاح على منصة خطوات العقار للأغراض الشخصية وغير التجارية فقط ضمن الحدود القانونية المسموح بها. أي استخدام آخر، بما في ذلك إعادة النشر أو التوزيع أو النسخ، يتطلب إذنًا صريحًا منا أو من أصحاب حقوق الملكية الفكرية المعنيين.
                </p>

                <h4 className="fw-normal">القيود</h4>
                <p>
                    يُحظر استخدام منصة خطوات العقار لأي غرض غير قانوني أو لانتهاك حقوق الملكية الفكرية لأطراف ثالثة. يشمل ذلك، على سبيل المثال لا الحصر، الاستخدام غير المصرح به للمحتوى، والمحاكاة، والتوزيع غير القانوني.
                </p>

                <h4 className="fw-normal">الإبلاغ عن انتهاكات حقوق الملكية الفكرية</h4>
                <p>
                    إذا كنت تعتقد أن عملك قد تم نسخه بطريقة تشكل انتهاكًا لحقوق الملكية الفكرية على منصة خطوات العقار، يرجى إبلاغنا بالمعلومات اللازمة لدعم مطالبك. سنتخذ الإجراءات المناسبة للتعامل مع الشكاوى وفقًا للقانون.
                </p>

                <h4 className="fw-normal">التعديلات على سياسة الملكية الفكرية</h4>
                <p>
                    نحتفظ بالحق في تعديل هذه السياسة في أي وقت. يُنصح بمراجعة هذه السياسة بانتظام لضمان فهمك للبنود الحالية.
                </p>

                <h4 className="fw-normal">اتصل بنا</h4>
                <p>
                    لأي أسئلة أو استفسارات بشأن سياسة الملكية الفكرية، يرجى الاتصال بنا على:
                    <a href="mailto:info@aqare.sa">info@aqare.sa</a> <br />
                    +966552668383
                </p>
              </div>

          )}

        </div>
      </div>
      <Footer />
    </>
  );
};

export default RightsPolicy;