import React, {useState,useEffect } from "react";
import Swal from 'sweetalert2';

import { Table, Dropdown, DropdownButton, InputGroup,
  Button, FormControl,
} from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ApiService from '../../../services/ApiService';
import { formatPrice } from '../../../utils/helper';
import { Link  } from 'react-router-dom';

const ListProperty = () => {
  const { t } = useTranslation();
  const app_lang=localStorage.getItem('lang') || 'en';
  const [properties, setProperties] = useState([]);
  const [pagination, setPagination] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [apiData, setApiData] = useState({
      search: "",
      daterange: "",
      page: 0
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getProperties();
  }, [apiData.page, apiData.search, apiData.daterange]);

  const getProperties = async () => {
    try {
      setIsLoader(true);
      const response = await ApiService.request({
        method: 'GET',
        url: `properties`, // Replace with your API endpoint
        params: apiData
      });
      const data = response.data;
      if (data.status) {
        setProperties(data.data);
        setPagination(data.pagination);
      } else {
        toast.error(data.message);
      }
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
    }
  };
  const delData = async (id) => {
    const result = await Swal.fire({
        title: t('are_you_sure'),
        text: t('not_able_revert'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: t('yes'),
        cancelButtonColor: '#9E9B9B',
        cancelButtonText: t(t('no')),
    });

    if (result.isConfirmed) {
        const response = await ApiService.request({
            method: "DELETE",
            url: `properties/${id}`, // Replace with your API endpoint
        });
        const data = response.data;
        if (data.status) {
            getProperties();
            toast.success(data.message);
        } else {
            toast.error(data.message);
        }
    }
};


const updateStatus = async (id) => {
    const result = await Swal.fire({
        title: t('are_you_sure'),
        text: t('want_change_status'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#4BC95F',
        confirmButtonText: t('yes'),
        cancelButtonColor: '#9E9B9B',
        cancelButtonText: t(t('no')),
    });

    if (result.isConfirmed) {
        const response = await ApiService.request({
            method: "POST",
            url: `properties/status/${id}`, // Replace with your API endpoint
        });
        const data = response.data;
        if (data.status) {
            getProperties();
            toast.success(data.message);
        } else {
            toast.error(data.message);
        }
    }
};


  return (
    <div>
      <div className="real-estate-dashboard">
        <div className="d-flex justify-content-between align-items-center rounded estate-search-bar mt-5">
          <InputGroup className="search-bar">
            <FormControl
              placeholder={t('search_title')}
              className="custom-width"
              value={apiData.search} onChange={e => setApiData({ ...apiData, search: e.target.value })}
            />
            <i className="bi bi-search estate-search"></i>
          </InputGroup>
          {/* <Link  className="btn text-white btn-base rounded-pill w-25" to={`/dashboard?tab=add-property`}>{t('add_property')}</Link> */}
          <Link  className="btn text-white btn-base rounded-pill w-25" to={`/nafath-auth`}>{t('add_property')}</Link>
        </div>
      </div>
      <div className="table-estate p-3 mt-3 rounded bg-white">
      {isLoader && <p className="text-center"><span class="spinner-border spinner-border-sm "></span> Loading</p> }

        <Table responsive striped hover className="estate-table mt-3">
          <thead>
            <tr>
              <th>{t('thumbnail')}</th>
              <th>{t('title')}</th>
              <th className="price">{t('price')}</th>
              <th className="expires">{t('expires_on')}</th>
              <th className="status">{t('status')}</th>
              <th className="">{t('action')}</th>
            </tr>
          </thead>
          <tbody>
            {properties.length > 0 ? properties.map((property) => (
              <tr key={property.id}>
                <td>
                  <img src={property?.media_files[0]?.file} alt="Thumbnail" width="75" />
                </td>
                <td>
                  {property.title}
                    <span className="badge bg-danger ms-2"> {property.condition}</span>
                  <div className="text-muted small">
                    <span>
                      <i className="bi bi-clock"></i> {property.created_at}
                    </span>
                    <span className="ms-3">
                      <i className="bi bi-tag"></i> {property?.category?.name}
                    </span>
                    <span className="ms-3">
                      <i className="bi bi-eye"></i> {property.views} {t('views')}
                    </span>
                  </div>
                </td>
                <td className="price">{t("currency")} {property.price}</td>
                <td className="expires">{property.expiry} {property.expiry_date}</td>
                <td className="status">
                        <button className="p-0 btn btn-transparent">
                            <span className={`badge ${property.status === 1 ? 'bg-success' : 'bg-danger'}`} onClick={() => updateStatus(property.id)} >
                                {property.status === 1 ? t("active") : t("inactive")}
                            </span>
                        </button>
                        <br />
                        {(property.is_approved == 0)?(
                          <span className="alert alert-warning text-sm p-0 m-1 d-block" >{t("Pending")}</span>
                        ) : property.is_approved === 1 ? (
                          <span className="alert alert-success text-sm p-0 m-1 d-block">{t("Approved")}</span>
                        ) : (
                          <span className="alert alert-danger text-sm p-0 m-1 d-block">{t("Rejected")}</span>
                        )}
                </td>
                <td className="estate-menu ">
                  <DropdownButton id="dropdown-basic-button" title="...">
                    {/* <Dropdown.Item href="#/promotion">
                      <i className="menu-icons bi bi-megaphone"></i>
                      Promotion
                    </Dropdown.Item> */}
                    <Link className="dropdown-item" to={`/dashboard/?tab=add-property&id=${property.id}`} > <i className="menu-icons bi bi-pencil"></i> {t('edit')}</Link>
                    <span className="btn dropdown-item" onClick={() => delData(property.id)} > <i className="menu-icons bi bi-trash"></i> {t('delete')}</span>
                    {/* <Link className="dropdown-item" to="" > <i className="menu-icons bi bi-check-circle"></i> Mark as Sold</Link> */}
                  
                  </DropdownButton>
                </td>
              </tr>
            )) : (
              <tr>
                  <td colSpan="11" className="text-dark text-center mt-2">{t('no_data')}!</td>
              </tr>
          )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ListProperty;
