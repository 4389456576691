import React, { useState } from "react";
import { toast } from "react-toastify";
import Topbar from "../components/Top Bar/Topbar";
import CustomNavbar from "../components/navBar/NavBar";
import Footer from "../components/Footer/Footer";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ApiService from '../services/ApiService';


const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);

  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoader(true);
    try {
      const response = await ApiService.request({
        method: 'POST',
        url: `login`, // Replace with your API endpoint
        data: formData
      });
      const data = response.data;
      if (data.status) {
        toast.success(data.message);
        let user = data.data.user;
        user.auth_token = data.data.auth_token;
        localStorage.setItem('user', JSON.stringify(data.data.user));
        window.location.href='/';
        navigate('/');
      } else {
        toast.error(data.message);
      }
      setIsLoader(false);
    } catch (error) {
      toast.error("Login failed!");
      setIsLoader(false);
    }
  };

  return (
    <>
      <Topbar />
      <CustomNavbar />

      <Container className="d-flex justify-content-center align-items-center loginForm mb-5">
        <Row>
          <Col>
            <div className="p-5 border rounded shadow bg-white form-width">
              <h3 className="text-center mb-4 fw-lighter">{t("login")}</h3>
              <Form method="post" onSubmit={handleSubmit}>
                <Form.Group controlId="formUsernameEmail">
                  <Form.Label className="fw-light">
                  {t("u_name_Email")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control type="text" className="form-input" required 
                    name="username" onChange={handleChange} value={formData.username} />
                </Form.Group>

                <Form.Group controlId="formPassword" className="mt-3">
                  <Form.Label className="fw-light">
                  {t("pass")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    className="form-input"
                    required
                    name="password" onChange={handleChange} value={formData.password}
                  />
                </Form.Group>

                <div className="d-flex justify-content-between align-items-center mt-3">
                   
                  <Button className="form-btn" type="submit" disabled={isLoader} >
                  {t("login")}  {isLoader && <span class="spinner-grow spinner-grow-sm"></span>}
                  </Button>
                  <Form.Check type="checkbox" label={t('remember_me')} />
                </div>

                <div className="mt-3 fw-light">
                    <Link to={"/register"}>
                  <a className="text-secondary">
                         {t("register")}
                  </a>
                  </Link>
                  <span className="mx-2">|</span>
                  <a href="/forgot-password" className="text-secondary">
                      {t("forgot")}
                  </a>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Login;
