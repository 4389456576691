import React, { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";

import Topbar from "../components/Top Bar/Topbar";
import CustomNavbar from "../components/navBar/NavBar";
import Footer from "../components/Footer/Footer";
import { NavLink  } from 'react-router-dom';
import PageLoader from "../components/PageLoader";
import ApiService from '../services/ApiService';
import { toast } from "react-toastify";



const Packages = () => {
  const { t } = useTranslation();
  const [isPageLoader, setPageLoader] = useState(false);
  const [packages, setPackages] = useState([]);
  const app_lang=localStorage.getItem('lang') || 'en';

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllPackages();
  }, []);
  
  const getAllPackages = async () => {
    try {
      setPageLoader(true);
      const response = await ApiService.request({
        method: 'GET',
        url: `getAllPackages`, // Replace with your API endpoint
      });
      const data = response.data;
      if (data.status) {
        setPackages(data.data);
      } else {
        toast.error(data.message);
      }
      setPageLoader(false);
    } catch (error) {
      setPageLoader(false);
    }
  };

  return (
    <div>
      {isPageLoader ? (
        <PageLoader />
      ):(
      <div className="main-en">
        <Topbar />
        <CustomNavbar />
        {/* Header */}
        <div className="estate-header">
          <div className="header-txt text-white">
            <p>
            <NavLink to="/" className="nav-link d-inline">  {t('home')} </NavLink>  
            <i className={`bi ${app_lang === 'en' ? 'bi-chevron-right' : 'bi-chevron-left'}`}></i> {t('packages')}
            </p>
          </div>
        </div>

        {/* Package Body */}
        <div className="container">
          <div className="text-center my-5">
            <h1>{t('ad_packages')}</h1>
          </div>
          <div className="row justify-content-center">
            {packages.map((pkg) => (
              <div className="col-md-3" key={pkg.id}>
                <div className="package1 text-center my-5">
                  <div className="package-head">
                    <h2>{pkg.name}</h2>
                    {/* <h2>
                      {pkg.type === 'per_ad' ?(
                        <span className="">{t('one_time_package')}</span>
                      ):(
                        <span className="">{pkg.no_ads} {t('license_package')}</span>
                      )} 
                    </h2> */}

                    <h1>{t('currency')} {pkg.price} <sub>\ {t('ads')}</sub></h1>
                  </div>
                  <div className="package-body">
                    <p>
                      {pkg.description}
                    </p>
                    <div className="package-btn">
                      <NavLink   to={"/purchase-package/"+pkg.id}>{t('choose_package')}</NavLink>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          
          </div>
        </div>

        {/* Package Footer */}
        <div className="container-fluid package-footer">
        <div className="container d-flex justify-content-between align-items-center">
          <div>
            <h2>{t('package_footer_title')}</h2>
            <p className="mt-2 fw-lighter">{t('package_footer_desc')}</p>
          </div>
          <div className="package-footer-btn">
            <button>{t('contact_us')}</button>
          </div>
        </div>
      </div>

        <Footer />
      </div>
      )}
    </div>
  );
};

export default Packages;
