import React, {useState,useEffect } from "react";
import Swal from 'sweetalert2';

import { Table, Dropdown, DropdownButton, InputGroup,
  Button, FormControl,
} from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ApiService from '../../../services/ApiService';
import { formatPrice } from '../../../utils/helper';
import { Link  } from 'react-router-dom';
import Paginator from "../../../components/Paginator"; 

const ListFavourite = () => {
  const { t } = useTranslation();
  const app_lang=localStorage.getItem('lang') || 'en';
  const [properties, setProperties] = useState([]);
  const [pagination, setPagination] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [apiData, setApiData] = useState({
      search: "",
      page: 0
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    listFavourites();
  }, [apiData.page, apiData.search]);

  const listFavourites = async () => {
    try {
      setIsLoader(true);
      const response = await ApiService.request({
        method: 'GET',
        url: `listFavourites`, // Replace with your API endpoint
        params: apiData
      });
      const data = response.data;
      if (data.status) {
        setProperties(data.data.favoutes);
        setPagination(data.data.pagination);
      } else {
        toast.error(data.message);
      }
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
    }
  };
  const delData = async (id) => {
    const result = await Swal.fire({
        title: t('are_you_sure'),
        text: t('not_able_revert'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonColor: '#9E9B9B',
        cancelButtonText: t('no'),
    });

    if (result.isConfirmed) {
        const response = await ApiService.request({
            method: "POST",
            url: `addRemoveFavourite`, // Replace with your API endpoint
            data:{property_id:id}
        });
        const data = response.data;
        if (data.status) {
            listFavourites();
            toast.success(data.message);
        } else {
            toast.error(data.message);
        }
    }
  };
  const handlePageChange = (event) => {
    setApiData(prev => ({ ...prev, page: event }));
  };


  return (
    <div>
      <div className="real-estate-dashboard">
        <div className="d-flex justify-content-between align-items-center rounded estate-search-bar mt-5">
          {/* <InputGroup className="search-bar">
            <FormControl
              placeholder="Search by title"
              className="custom-width"
              value={apiData.search} onChange={e => setApiData({ ...apiData, search: e.target.value })}
            />
            <i className="bi bi-search estate-search"></i>
          </InputGroup> */}
          <h5 className="p-3">{t('favourite_properties')}</h5> 
        </div>
      </div>
      <div className="table-estate p-3 mt-3 rounded bg-white">
      {isLoader && <p className="text-center"><span class="spinner-border spinner-border-sm "></span> Loading</p> }

        <Table responsive striped hover className="estate-table mt-3">
          <thead>
            <tr>
              <th>{t('thumbnail')}</th>
              <th>{t('title')}</th>
              <th className="price">{t('price')}</th>
              <th className="expires">{t('expires_on')}</th>
              <th className="">{t('action')}</th>
            </tr>
          </thead>
          <tbody>
            {properties.length > 0 ? properties.map((property) => (
              <tr key={property.property.id}>
                <td>
                  <img src={property.property?.media_files[0]?.file} alt="Thumbnail" width="75" />
                </td>
                <td>
                  {property.property.title}
                    <span className="badge bg-danger ms-2"> {property.property.condition}</span>
                  <div className="text-muted small">
                    <span>
                      <i className="bi bi-clock"></i> {property.property.created_at}
                    </span>
                    <span className="ms-3">
                      <i className="bi bi-tag"></i> {property.property?.category?.name}
                    </span>
                    <span className="ms-3">
                      <i className="bi bi-eye"></i> {property.property.views} {t('views')}
                    </span>
                  </div>
                </td>
                <td className="price">{t("currency")} {formatPrice(property.property.price)}</td>
                <td className="expires">{property.property.expiry} {property.property.expiry_date}</td>
               
                <td className="estate-menu ">
                  
                    <span className="btn dropdown-item" onClick={() => delData(property.property.id)} > <i className="menu-icons bi bi-trash"></i> </span>
                </td>
              </tr>
            )) : (
              <tr>
                  <td colSpan="11" className="text-dark text-center mt-2">{t('no_data')}!</td>
              </tr>
          )}
          </tbody>
        </Table>
          {pagination.total_pages > 1 && (
                  <Paginator
                      page={pagination.current_page}
                      rows={pagination.per_page}
                      totalRecords={pagination.total_records}
                      onPageChange={handlePageChange}
                  />
              )}
      </div>
    </div>
  );
};

export default ListFavourite;
