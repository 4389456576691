
import React, {  useEffect } from "react";
import Topbar from "../components/Top Bar/Topbar";
import Footer from "../components/Footer/Footer";
import CustomNavbar from "../components/navBar/NavBar";
import { useTranslation } from "react-i18next";
import { NavLink  } from 'react-router-dom';


const AdvertismentValidator = () => {
  const { t } = useTranslation();
  const app_lang=localStorage.getItem('lang') || 'en';

  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


 

  return (
    <>
      <Topbar />
      <CustomNavbar />
      
      <div className="estate-header">
        <div className="header-txt text-white">
          <p>
              <NavLink to="/" className="nav-link d-inline">  {t('home')} </NavLink>  
              <i className={`bi ${app_lang === 'en' ? 'bi-chevron-right' : 'bi-chevron-left'}`}></i> {t('ad_type')} 
          </p>
        
        </div>
      </div>

      <div className="container mb-5">
          <div className="bg-white p-5 mt-5 rounded" style={{ minHeight: '350px' }}>
            <h2 className="fw-normal"> {t('ad_type')} </h2>
            <h3 className="text-center mt-5 text-secondary">{t('chose_ad_type')}</h3>
            <div className="row mt-4">
              <div className="col-md-8 col-12 mx-auto">
                <NavLink to="/advertisment-validator"
                 className="btn btn-outline-secondary d-block my-3 py-3">
                  <p className="h4">
                  {t('ad_option1')}
                  </p>
                </NavLink> 

                <NavLink to="/dashboard?tab=add-property"
                 className="btn btn-outline-secondary d-block my-3 py-3">
                  <p className="h4">
                  {t('ad_option2')}
                  </p>
                </NavLink>
                
              </div>
            
            </div>
          </div>
      </div>
      <Footer />
    </>
  );
};

export default AdvertismentValidator;