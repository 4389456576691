import React, {useState,useEffect } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; 
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ApiService from '../../../services/ApiService';
import { Link ,useParams, useNavigate, useLocation } from 'react-router-dom';
import GoogleMap from '../../../components/GoogleMap';

const ListProperty = () => {
  const { t } = useTranslation();
 
  const loc = useLocation();
  const queryParams = new URLSearchParams(loc.search);
  const id = queryParams.get("id"); 
  const [user] = useState(JSON.parse(localStorage.getItem('user')));

  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [features, setFeatures] = useState([]);
  const [types, setPropertyTypes] = useState([]);
  const [location, setLocation] = useState({
    lat: 0,
    lng: 0,
    text:''
  });
  const [files, setFiles] = useState([]);

  const [isLoader, setIsLoader] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    title_ar: '',
    description: '',
    description_ar: '',
    list_type: '',
    category_id: '',
    pricing: 'price',
    price_type: 'Fixed',
    price: '',
    max_price: '',
    expiry: 'Never',
    expiry_date: '',
    features:[],
    property_type:'',
    parking:'Yes',
    bedrooms:'',
    bathrooms:'',
    area:'',
    condition:'Old',
    construction_year:'',
    media_files:[]
  });

  // Function to handle file drop and convert files to base64
  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        setFiles((prevFiles) => [
          ...prevFiles,
          { id: file.name + Date.now(), file, preview: reader.result },
        ]);
      };
      reader.readAsDataURL(file); // Convert file to base64
    });
  };
    // Handle rejected files
  const onDropRejected = (rejectedFiles) => {
    const sizeErrors = rejectedFiles.map((file) => ({
      file: file.file.name,
      message: `File ${file.file.name} exceeds the maximum size of 5 MB.`,
    }));
    toast.error(sizeErrors);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      "image/*": [], // Accept all image types
      "video/*": [], // Accept all video types
    },
    multiple: true, // Allow multiple file uploads
    maxSize : 5 * 1024 * 1024, // 5 MB in bytes
  });
   // Remove file handler
  const removeFile = (fileId) => {
    setFiles((currentFiles) => currentFiles.filter((file) => file.id !== fileId));
  };
  const  removeFileDB = async (fileId) => {
    const updatedMediaFiles = formData.media_files.filter((file) => file.id !== fileId);
    // Update the state with the new array
    setFormData((prevFormData) => ({
      ...prevFormData,
      media_files: updatedMediaFiles,
    }));
    const response = await ApiService.request({
        method: "DELETE",
        url: `removeFile/${fileId}`, // Replace with your API endpoint
    });
    const data= response.data;
    if (data.status) {
        toast.success(data.message);
    } else {
        toast.error(data.message);
    }
  };

  useEffect(() => {
    console.log(user);
    if(!user.subscription_status){
      toast.error("you don't have nay valid subscription!");
      navigate('/packages');
    }
    else if(!user.agency_id){
      toast.error("you need to setup agency account first!");
      navigate('/dashboard?tab=agency');
    }
    if (id) {
      getSingleData();
    }
    getDropdownData();
  }, [id]);
 

  const getSingleData = async () => {
    setIsLoader(true);
    try {
      const response = await ApiService.request({
        method: 'GET',
        url: `properties/${id}`, // Replace with your API endpoint
      });
      const data = response.data;
      if (data.status) {
        setFormData(data.data);
        setLocation({
          lat: data.data.lat,
          lng: data.data.lng,
          text: data.data.address,
        });
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoader(false);
    }
  };

  const getDropdownData = async () => {
    setIsLoader(true);
    try {
      const response = await ApiService.request({
        method: 'GET',
        url: `getDropdownData?type=listing`, // Replace with your API endpoint
      });
      const data = response.data;
      if (data.status) {
        setCategories(data.data.categories);
        setFeatures(data.data.features);
        setPropertyTypes(data.data.types);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoader(false);
    }
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleFeatureChange = (e) => {
    const { value } = e.target;
    setFormData((prevFormData) => {
      const features = prevFormData.features.includes(value)
        ? prevFormData.features.filter((feature) => feature !== value) // remove if already exists
        : [...prevFormData.features, value]; // add if not exists
      return { ...prevFormData, features };
    });
  };
  const handleLocationChange = (location) => {
    setLocation(location);
  };
  const handleQuillChange = (name) => (value) => {
    // Create a synthetic event object
    const syntheticEvent = {
      target: {
        name: name, 
        value: value, 
      },
    };
    // Call the existing handleChange function
    handleChange(syntheticEvent);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoader(true);

    try {
      const data = new FormData();
      Object.keys(formData).forEach((key) => {
        data.append(key, formData[key]);
      });
      data.append('location', location.text);
      data.append('lat', location.lat);
      data.append('lng', location.lng);
      // data.append('files', files);
      Array.from(files).forEach((file) => {
        data.append('files[]', file.file); // Use 'files[]' to allow multiple files
      });

      const response = await ApiService.request({
        method: 'POST',
        url: `properties/createOrUpdate/${id || ''}`, // Replace with your API endpoint
        data,
      });

      if (response.data.status) {
        toast.success(response.data.message);
        navigate('/dashboard?tab=properties');
      } else {
        toast.error(response.data.message);
      }
      setIsLoader(false);
    } catch (error) {
      console.error('Form submission failed:', error);
    } finally {
      setIsLoader(false);
    }
  };

  return (
    <div>
      <div className="real-estate-dashboard">
        <div className="d-flex justify-content-between align-items-center rounded estate-search-bar mt-5">
          <h5 className="m-3">{t('add_property')}</h5>
        </div>
      </div>
      <div className="table-estate p-3 mt-3 rounded bg-white">
        {isLoader && <p className="text-center"><span class="spinner-border spinner-border-sm "></span> Loading</p> }
              <form onSubmit={handleSubmit} className="form fv-plugins-bootstrap5 fv-plugins-framework">
                <div className="row g-9 my-3">
                  <div className="col-md-6 my-2 fv-plugins-icon-container">
                    <label className="required fs-6 fw-semibold mb-2"> {t('title')}</label>
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      placeholder={t('title')}
                      required
                      name="title"
                      value={formData.title}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 my-2 fv-plugins-icon-container">
                    <label className="required fs-6 fw-semibold mb-2"> {t('title_ar')}</label>
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      placeholder={t('title_ar')}
                      required
                      name="title_ar"
                      value={formData.title_ar}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md-6 my-2 fv-plugins-icon-container">
                    <label className=" fs-6 fw-semibold mb-2"> {t('description')} </label>
                    <ReactQuill value={formData.description} name="description"
                      placeholder={t('description')}
                      style={{ height: "250px" }} onChange={handleQuillChange('description')} />
                      <br /> <br />
                  </div>
                  <div className="col-md-6 my-2 fv-plugins-icon-container">
                    <label className="required fs-6 fw-semibold mb-2"> {t('description_ar')}</label>
                    <ReactQuill value={formData.description_ar} name="description_ar"
                      placeholder={t('description_ar')}
                      style={{ height: "250px" }} onChange={handleQuillChange('description_ar')} />
                      <br /> <br />
                  </div>

                  <div className="col-md-6 my-2 fv-plugins-icon-container">
                    <label className="required fs-6 fw-semibold mb-2"> {t('listing_type')}</label>
                    <select name="list_type"  onChange={handleChange}  value={formData.list_type || ""}
                      className='form-select form-control-solid'>
                      <option value="">{t('listing_type')}</option>
                      <option value="For Sell">  {t('for_sell')} </option>
                      <option value="For Rent"> {t('for_rent')} </option>
                    </select>
                  </div>
                  <div className="col-md-6 my-2 fv-plugins-icon-container"> 
                    <label className="required fs-6 fw-semibold mb-2"> {t('category')}</label>
                    <select name="category_id"  onChange={handleChange}  value={formData.category_id}
                      className='form-select form-control-solid'>
                      <option value=""> {t('category')}</option>
                      {categories.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6 my-2 fv-plugins-icon-container">
                    <label className=" fs-6 fw-semibold mb-3"> {t('expiry')}: </label> <br />
                    <label className=" fs-6  me-10"  >
                      <input type="radio" name="expiry"  className="form-check-input large-radio" 
                       checked={formData.expiry === "Never"}  onChange={handleChange} value="Never" /> {t('never_expire')}
                    </label>
                    <label className=" fs-6 me-10 " >
                      <input type="radio" name="expiry"  className="form-check-input large-radio ms-4" 
                        checked={formData.expiry === "Date"}  onChange={handleChange} value="Date" />  {t('date')}
                    </label>
                  </div>
                    {formData.expiry === 'Date' && (
                          <div className="col-md-6 my-2 fv-plugins-icon-container">
                            <label className="required fs-6 fw-semibold mb-2"> {t('expiry_date')} </label>
                              <input   type="date" 
                                className="form-control form-control-solid"
                                placeholder="Enter Expiry Date"
                                required  name="expiry_date"
                                value={formData.expiry_date}
                                onChange={handleChange}
                              />
                          </div>
                    )}

                  <div className="col-md-6 my-2 fv-plugins-icon-container">
                    <label className=" fs-6 fw-semibold mb-3"> {t('pricing')}: </label> <br />
                    <label className=" fs-6  me-10"  htmlFor="price">
                      <input type="radio" name="pricing" id="price"  className="form-check-input large-radio " 
                       checked={formData.pricing === "price"}  onChange={handleChange} value="price" /> {t('price')}
                    </label>
                    <label className=" fs-6 me-10" htmlFor="range">
                      <input type="radio" name="pricing" id="range"  className="form-check-input large-radio ms-4" 
                        checked={formData.pricing === "range"}  onChange={handleChange} value="range" /> {t('price_range')}
                    </label>
                    <label className=" fs-6 me-10" htmlFor="disabled">
                      <input type="radio" name="pricing" id="disabled"  className="form-check-input large-radio ms-4" 
                        checked={formData.pricing === "disabled"}  onChange={handleChange} value="disabled" />  {t('disabled')}
                    </label>
                  </div>
                </div>
                

                  {formData.pricing !== 'disabled' && (
                      <div className='row g-9 my-1'>

                        <div className="col-md-6 my-2 fv-plugins-icon-container">
                          <label className="required fs-6 fw-semibold mb-2"> {t('price_type')}</label>
                          <select name="price_type"  onChange={handleChange}  value={formData.price_type}
                            className='form-select form-control-solid'>
                              <option value="Fixed">  {t('fixed')} </option>
                              <option value="Negotiable"> {t('negotiable')} </option>
                              <option value="On Call"> {t('on_call')} </option>
                          </select>
                        </div>

                        {formData.price_type !== 'On Call' && (
                          <div className="col-md-6 my-2 fv-plugins-icon-container">
                            <label className="required fs-6 fw-semibold mb-2"> {t('price')}</label>
                            <div className="input-group" >
                              <input   type="number" step={0.01}
                                className="form-control form-control-solid"
                                placeholder={t('price')}
                                required  name="price"
                                value={formData.price}
                                onChange={handleChange}
                              />
                              <div className="input-group-addon bg-light border px-3 py-2">
                                  <span>{t('currency')}</span>
                              </div>
                            </div>
                          </div>
                        )}
                        {formData.price_type !== 'On Call' && formData.pricing === 'range' && (
                          <div className="col-md-6 my-2 fv-plugins-icon-container">
                            <label className="required fs-6 fw-semibold mb-2">{t('max_price')} </label>
                            <div className="input-group" >
                              <input   type="number" step={0.01}
                                className="form-control form-control-solid"
                                placeholder={t('max_price')}
                                required  name="max_price"
                                value={formData.max_price}
                                onChange={handleChange}
                              />
                              <div className="input-group-addon bg-light border px-3 py-3">
                                  <span>{t('currency')}</span>
                              </div>
                            </div>
                          </div>
                        )}

                        
                      </div>
                  )}

                <div className='row g-9 my-1'>

                  <div className="col-md-6 my-2 fv-plugins-icon-container">
                    <label className=" fs-6 fw-semibold mb-3"> {t('features')}</label> <br />
                    <div className="row">
                      {features.map((feature, index) => (
                        <div className="col-6 mb-3" key={index}>
                          <label className=" fs-6">
                            <input type="checkbox" className="form-check-input large-checkbox" value={feature.name} name='features' 
                            checked={formData.features.includes(feature.name)}  onChange={handleFeatureChange}/>
                              {feature.name} 
                          </label>
                        </div>
                      ))}
                    </div>
                   
                 
                  </div>
                  <div className="col-md-6 my-2 fv-plugins-icon-container">
                    <label className=" fs-6 fw-semibold mb-3"> {t('property_type')}</label> <br />
                    <div className="row">
                      {types.map((type, index) => (
                        <div className="col-6 mb-3" key={index}>
                          <label className=" fs-6">
                            <input type="radio" className="form-check-input large-radio" value={type.name} name='property_type' 
                              checked={formData.property_type === type.name}  onChange={handleChange}/>
                              {type.name} 
                          </label>
                        </div>
                      ))}
                    </div>
                   
                 
                  </div>
                  <div className="col-md-6 my-2 fv-plugins-icon-container">
                    <label className=" fs-6 fw-semibold mb-3"> {t('parking')}</label> <br />
                    <div className="row">
                        <div className="col-4 mb-3">
                          <label className=" fs-6">
                            <input type="radio" className="form-check-input large-radio" value="Yes" name='parking' 
                              checked={formData.parking === 'Yes'}  onChange={handleChange}/>
                              {t('yes')}  
                          </label>
                        </div>
                        <div className="col-4 mb-3">
                          <label className=" fs-6">
                            <input type="radio" className="form-check-input large-radio" value="No" name='parking' 
                              checked={formData.parking === t('no')}  onChange={handleChange}/>
                                {t(t('no'))}  
                          </label>
                        </div>
                    </div>
                   
                 
                  </div>

                  <div className="col-md-6 my-2 fv-plugins-icon-container">
                    <label className="required fs-6 fw-semibold mb-2"> {t('bedrooms')}</label>
                    <input   type="number"
                      className="form-control form-control-solid"
                      placeholder={t('bedrooms')}
                      required  name="bedrooms"
                      value={formData.bedrooms}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 my-2 fv-plugins-icon-container">
                    <label className="required fs-6 fw-semibold mb-2"> {t('bathroom')}</label>
                    <input   type="number"
                      className="form-control form-control-solid"
                      placeholder= {t('bathroom')}
                      required  name="bathrooms"
                      value={formData.bathrooms}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 my-2 fv-plugins-icon-container">
                    <label className="required fs-6 fw-semibold mb-2">  {t('area')}</label>
                    <input   type="number"
                      className="form-control form-control-solid"
                      placeholder={t('area')}
                      required  name="area"
                      value={formData.area}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 my-2 fv-plugins-icon-container">
                    <label className="required fs-6 fw-semibold mb-2"> {t('construction_condition')}</label>
                    <div className="row">
                        <div className="col-4 mb-3">
                          <label className=" fs-6">
                            <input type="radio" className="form-check-input large-radio" value="Used" name='condition' 
                              checked={formData.condition === 'Used'}  onChange={handleChange}/>
                               {t('old')} 
                          </label>
                        </div>
                        <div className="col-4 mb-3">
                          <label className=" fs-6">
                            <input type="radio" className="form-check-input large-radio" value="New" name='condition' 
                              checked={formData.condition === 'New'}  onChange={handleChange}/>
                               {t('new')} 
                          </label>
                        </div>
                    </div>
                  </div>
                  <div className="col-md-6 my-2 fv-plugins-icon-container">
                    <label className="required fs-6 fw-semibold mb-2"> {t('construction_year')}</label>
                    <input   type="number"
                      className="form-control form-control-solid"
                      placeholder={t('construction_year')}
                      required  name="construction_year"
                      value={formData.construction_year}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-12 my-2 fv-plugins-icon-container">
                    <label className=" fs-5 fw-semibold text-base"> {t('contact_details')}:</label>
                  </div>
                  <div className="col-md-6 my-2 fv-plugins-icon-container">
                    <label className="required fs-6 fw-semibold mb-2"> {t('phone')}</label>
                    <input   type="number"
                      className="form-control form-control-solid"
                      placeholder={t('phone')}
                      required  name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 my-2 fv-plugins-icon-container">
                    <label className=" fs-6 fw-semibold mb-2"> {t('whatsapp')}</label>
                    <input   type="number"
                      className="form-control form-control-solid"
                      placeholder={t('whatsapp')}
                        name="whatsapp"
                      value={formData.whatsapp}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 my-2 fv-plugins-icon-container">
                    <label className=" fs-6 fw-semibold mb-2"> {t('email')}</label>
                    <input   type="email"
                      className="form-control form-control-solid"
                      placeholder={t('email')}
                        name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>

                </div>
                <label className=" fs-6 fw-semibold my-2"> {t('uplaod_media')}</label>
                <div
                  {...getRootProps({
                    className: "dropzone",
                    style: {
                      border: "2px dashed #ccc",
                      padding: "20px",
                      textAlign: "center",
                      cursor: "pointer",
                    },
                  })}
                  >
                  <input {...getInputProps()} />
                  <p> {t('dropbox_text')}</p>
                </div>

                <div className="preview d-flex bg-light border">
                  {files.map((file, index) => (
                    <div key={index} style={{ margin: "10px", textAlign: "center" }}>
                      {file.file.type.startsWith("image/") ? (
                        <img
                          src={file.preview}
                          alt="preview"
                          width="100"
                          height="100"
                          style={{ objectFit: "cover" }}
                        />
                      ) : (
                        <video
                          src={file.preview}
                          controls
                          width="100"
                          height="100"
                          style={{ objectFit: "cover" }}
                        />
                      )}
                      <p>{file.file.name}</p>
                      <button  type="button" className='btn btn-sm btn-transparent d-inline'
                        onClick={() => removeFile(file.id)}
                        style={{
                          cursor: "pointer",
                        }}>   X    
                      </button>
                    </div>
                  ))}
                  {/* //DB Files */}
                  {formData.media_files && formData.media_files.map((file, index) => (
                    <div key={index} style={{ margin: "10px", textAlign: "center" }}>
                      {file.type.startsWith("image/") ? (
                        <img
                          src={file.file}
                          alt="preview"
                          width="100"
                          height="100"
                          style={{ objectFit: "cover" }}
                        />
                      ) : (
                        <video
                          src={file.file}
                          controls
                          width="100"
                          height="100"
                          style={{ objectFit: "cover" }}
                        />
                      )}
                      <p>{file.name}</p>
                      <button  type="button" className='btn btn-sm btn-transparent d-inline'
                        onClick={() => removeFileDB(file.id)}
                        style={{
                          cursor: "pointer",
                        }}>   X    
                      </button>
                    </div>
                  ))}


                </div>

                <br />
                
                <label className=" fs-6 fw-semibold mb-2"> {t('Location')}</label>
                <GoogleMap locationNew={location} onLocationChanged={handleLocationChange}  />


                <div className="row g-9 my-3">
                  <div className="text-center">
                    <Link to="/properties" className="btn btn-light me-3">{t('back')}</Link>
                    <button type="submit" className="btn btn-base" disabled={isLoader}>
                      <span className="indicator-label">{isLoader ? <p className="text-center"><span class="spinner-border spinner-border-sm "></span> Loading</p> : t('submit')}</span>
                    </button>
                  </div>
                </div>
              </form>
      </div>
    </div>
  );
};

export default ListProperty;
