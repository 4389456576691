import React, {useState, useEffect} from 'react';
import { useNavigate , NavLink  } from 'react-router-dom';
import { Navbar, Nav, Container, Dropdown } from 'react-bootstrap';
import logo from "../../assets/img/logo.png";
import google from "../../assets/img/google.png";
import apple from "../../assets/img/apple.svg";
import "./nav.css";
import { useTranslation } from "react-i18next";
import LanguageToggle from "../../components/LanguageToggle";

const CustomNavbar = () => {

  const { t } = useTranslation();
  const [isSticky, setIsSticky] = useState(false);
  const [user] = useState(JSON.parse(localStorage.getItem('user')));

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Navbar expand="lg" className={` bg-white main-navbar shadow-sm p-3 rounded ${isSticky ? 'sticky' : ''}`}>
      <Container fluid>
         <NavLink to="/">
          <Navbar.Brand  className="mr-3">
            <img
              src={logo}
              height="60"
              width="auto"
              alt="Logo"
            />
          </Navbar.Brand>
         </NavLink>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="mx-auto">
            <NavLink to="/" className="nav-link">{t('home')}</NavLink>
            <NavLink to="/about-us" className="nav-link">{t('about_us')}</NavLink>
            <NavLink to="/properties" className="nav-link">{t('real_estate')}</NavLink>
            <NavLink to="/auctions" className="nav-link">{t('auctions')}</NavLink>
            <NavLink to="/search-by-map" className="nav-link">{t('search')}</NavLink>
            <NavLink to="/request-service" className="nav-link">{t('request')}</NavLink>
           <NavLink to="/nafath-auth" className="nav-link">{t('ad_registration')}</NavLink>
            <NavLink to="/packages" className="nav-link">{t('packages')}</NavLink>
          </Nav>
          <Nav className="ml-auto d-flex align-items-center">
            {/* <NavLink to="#" className="nav-link"> */}
              <LanguageToggle />
            {/* </NavLink> */}
            <Dropdown align="end">
              <Dropdown.Toggle variant="light" id="dropdown-download" className="bg-transparent border-0">
              {t('download_app')}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item to="#ios">
                  <img src={google} alt="app" width={'125px'} />
                </Dropdown.Item>
                <Dropdown.Item to="#android">
                  <img src={apple} alt="app" width={'120px'} />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
              {user ? (
                <NavLink to="/dashboard" className="  registerBtn">
                  <button className="btn btn-base btn-md p-2 "> {t('dashboard')} </button>
                </NavLink>
              ):(
                <NavLink to="/login" className=" registerBtn">
                   <button className="btn btn-base btn-md p-2 "> {t('login_register')} </button>
                </NavLink>
              )}
              {/* <i className="bi bi-person-circle" style={{ fontSize: '1.5rem' }}></i> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
