// components/LanguageToggle.js
import React , {useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {  NavLink } from "react-router-dom";
import { Carousel, Badge } from "react-bootstrap";
// import { formatPrice } from '../utils/helper';
import ApiService from '../services/ApiService';
import { toast } from 'react-toastify';

const PropertyCard = ({property}) => {
    const { t } = useTranslation();
    const [is_favourite, setIsFavourite] = useState(property.is_favourite);
    const user= JSON.parse(localStorage.getItem('user'));


    const addRemoveFavourite = async () => {
        if(!user){
            toast.error('You need to login first!');
            return;
        }
        try {
          const response = await ApiService.request({
            method: 'POST',
            url: `addRemoveFavourite`, // Replace with your API endpoint
            params: {property_id:property.id}
          });
          const data = response.data;
          if (data.status) {
            if (data.data== 'added') {
                setIsFavourite(true);
            }else{
                setIsFavourite(false);
            }
            
          } else {
            toast.error(data.message);
          }
        } catch (error) {
          console.error(error);
        } finally {
        }
    };

    return (
            <div className="property-card shadow-sm p-3 mb-5 bg-white rounded mt-3">
                <div className="position-relative">
                <NavLink to={`/property/`+property.slug}>
                <Carousel interval={null}>
                    {property.media_files.map((file) => (
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={file.file}
                        alt="Property img"
                        />
                    </Carousel.Item>
                    ))}
                </Carousel>
                </ NavLink>

                <Badge bg="primary" className="badge-sale">
                    {property.list_type}
                </Badge>

                <Badge bg="danger" className="badge-top">
                    {property.condition} 
                </Badge>

                <div className="hover-icons text-white mb-3 mr-3">
                    <i className={`bi ${is_favourite ? 'bi-heart-fill' : 'bi-heart'}`}  onClick={() => addRemoveFavourite()}></i>
                    <i className="bi bi-zoom-in"></i>
                </div>
                {property.pricing!=='disabled' && 
                    <div className="price-overlay">
                    <span>
                        {t("currency")} {property.price}
                        { property.pricing==='range' &&
                        <span>  -   {t("currency")} {property.max_price}</span>
                        }
                    </span>
                    </div>
                }
                </div>

                <div className="mt-3 text-start">
                <span className="badge bg-secondary mb-2">
                    {property.category.name}
                </span>
                
                <NavLink to={`/property/`+property.slug}>
                    <h5 className="text-dark">{property.title}</h5>
                    <p className="text-muted">{property.address}</p>
                </NavLink>
                <div className="d-flex justify-content-between">
                    <span>{t("bedrooms")}: {property.bedrooms}</span>
                    <span>{t("bathroom")}: {property.bathrooms}</span>
                    <span>{property.area} {t("area")}</span>
                </div>
                </div>
                <hr />
                <div className="slider-card-footer">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                    <img src={property.agency.logo} className="w-50px h-50px rounded-circle" alt="Icon" />
                    <h4 className="m-2">{property.agency.name}</h4>
                    </div>
                    <div>
                    <NavLink
                        to={`/property/`+property.slug}
                        className="btn btn-outline-secondary"
                    >
                        {t("details")}
                    </NavLink>
                    </div>
                </div>
                </div>
            </div>
    );
};

export default PropertyCard;
