import React, { useState,useRef ,useEffect } from "react";
import Topbar from "../components/Top Bar/Topbar";
import CustomNavbar from "../components/navBar/NavBar";
import Footer from "../components/Footer/Footer";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ApiService from '../services/ApiService';
import { auth } from '../utils/firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';


const RegistrationForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countdown, setCountdown] = useState(30);

  useEffect(() => {
    if (isOtpSent && countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown - 1 === 0) {
            setIsOtpSent(false); 
          }
          return prevCountdown - 1;
        });
      }, 1000); 

      return () => clearInterval(timer); 
    }
  }, [isOtpSent, countdown]);


  const generateRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      // If recaptchaVerifier doesn't exist, create it
      window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
        size: 'invisible',
        callback: (response) => {
          console.log("reCAPTCHA solved");
        }
      }, auth);
    }
    // Use the existing recaptchaVerifier if it exists
    console.log("Using existing reCAPTCHA");
  };

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const sendOTP = async () => {
    if (!phone) {
      toast.error('Phone number is required');
      return;
    }

    setIsLoading(true);

    try {
      generateRecaptcha();
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, "+"+phone, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setIsOtpSent(true);
        setCountdown(30);
        toast.success('OTP sent successfully!');
        setIsLoading(false);
      }).catch((error) => {
        toast.error(error.message);
        console.log(error);
        setIsLoading(false);
      });
    } catch (error) {
      console.error('Error sending OTP:', error);
      toast.error(`Error: ${error.message}`);
      setIsLoading(false);
    }
  };

  const verifyOTP = async () => {
    if (!otp) {
      toast.error('OTP is required');
      return;
    }
    let confirmationResult = window.confirmationResult;
      confirmationResult.confirm(otp).then((result) => {
        // User signed in successfully.
        let user = result.user;
        setPhoneVerified(true);
        console.log(user);
        toast.success('OTP verified successfully');
      }).catch((error) => {
        toast.error('Incorrect otp!');
      });
  };

  const register = async (e) => {
    try {
      e.preventDefault();
      formData.phone=phone;
      const response = await ApiService.request({
        method: 'POST',
        url: `register`, // Replace with your API endpoint
        data: formData
      });
      const data = response.data;
      if (data.status) {
        toast.success(data.message);
        let user = data.data.user;
        user.auth_token = data.data.auth_token;
        localStorage.setItem('user', JSON.stringify(data.data.user));
        navigate('/');
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error);
      console.log(error);
    }
  };

  return (
    <>
      <Topbar />
      <CustomNavbar />
      <Container className="d-flex justify-content-center align-items-center loginForm mb-5">
        <Row>
          <Col>
            <div className="p-4 border rounded shadow bg-white form-width">
              <h3 className="text-center mb-4 fw-lighter">{t("registration")}</h3>
              <Form method="post" onSubmit={register}>
                <Form.Group controlId="">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <Form.Label className="">
                      {t("f_name")} <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text" 
                        name="first_name" onChange={handleChange} value={formData.first_name}
                        className="form-input"
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label className="">
                      {t("l_name")} <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="last_name" onChange={handleChange} value={formData.last_name}
                        className="form-input"
                        required
                      />
                    </div>
                  </div>
                </Form.Group>

                <Form.Group  className="mt-3" style={{ direction: 'ltr' }}>
                  <label className="form-label fw-light">
                  {t("p_num")}<span className="text-danger"> *</span>
                  </label>
                  <PhoneInput
                    className="phoneInp"
                    country="sa"
                    onChange={setPhone} value={phone}
                    onlyCountries={["sa"]}
                    countryCodeEditable={false}
                    inputProps={{
                      name: "phone",
                      required: true,
                    }}
                    style={{ width: "500px !important" }}
                  />
                  <div id="recaptcha-container"></div>
                    <br />
                  <Button className="otpBtn " onClick={sendOTP} disabled={isLoading || isOtpSent}>{t("snd_otp")}
                  {isLoading && <span className="ms-2 spinner-grow  spinner-grow-sm"></span>}
                  </Button> 
                  {isOtpSent && 
                    <p className="bg-light border rounded px-3 py-2 d-inline m-3 "> {countdown} sec</p>
                  }
                </Form.Group>
                  

                <Form.Group  className="mt-3" style={{ direction: 'ltr' }}>
                  <Form.Label className="">
                  {t("otp_code")} <span className="text-danger">*</span>
                  </Form.Label>

                  <div className="input-group">
                    <Form.Control type="number"  className="form-input" required onChange={(e) => setOtp(e.target.value)}  value={otp} />
                    <Button className="otpBtn2" onClick={verifyOTP}>{t("v_otp")}</Button>
                  </div>
                </Form.Group>

                <Form.Group  className="mt-3">
                  <Form.Label className="">
                  {t("u_name")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control type="text" className="form-input" required
                    name="username" onChange={handleChange} value={formData.username}
                    />
                  <p className="text-secondary fw-lighter">
                  {t("u_name_not")}
                  </p>
                </Form.Group>

                <Form.Group  className="mt-3">
                  <Form.Label className="">
                  {t("email")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control type="email" className="form-input" required
                   name="email" onChange={handleChange} value={formData.email} />
                </Form.Group>

                <Form.Group  className="mt-3">
                  <Form.Label className="">
                  {t("pass")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    className="form-input"
                    required
                    name="password" onChange={handleChange} value={formData.password}
                  />
                </Form.Group>
                <Form.Group  className="mt-3">
                  <Form.Label className="">
                  {t("c_pass")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    className="form-input"
                    required
                    name="confirm_password" onChange={handleChange} value={formData.confirm_password}
                  />
                </Form.Group>

                <div className="mt-3">
                  <Form.Check
                    type="checkbox"
                    className="formCheck mb-3 fs-6 fw-light"
                    label={t('register_accept_policy')}
                  />
                  <div className="d-flex">
                    <Button className="btn btn-base px-5 me-3"  disabled={!phoneVerified} type="submit">
                    {t("register")}
                    </Button>
                    <div className="mt-3 fw-light">
                      <span className="text-secondary fw-lighter">
                      {t("already_login")}
                      </span>
                      <span className="mx-2 fw-lighter">|</span>
                      <Link to={"/login"}>
                        <a className="text-secondary fw-lighter">{t("here")}</a>
                      </Link>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default RegistrationForm;
