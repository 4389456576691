// Loader.js
import React from 'react';
import loader from "../assets/img/loader.gif";

const PageLoader = () => (
  <div className='bg-white' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <p>
        <img src={loader} alt="logo" className='w-100'  /> 
        {/* <div className="d-flex justify-content-center">
             <p className="spinner-border "  role="status"></p>
        </div> */}
    </p>
  </div>
);

export default PageLoader;
