import React, { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../App.css";
import Navbar from "../components/navBar/NavBar";
import Footer from "../components/Footer/Footer";
import "../components/header/header.css";
import Topbar from "../components/Top Bar/Topbar";
import "react-range-slider-input/dist/style.css";
import { NavLink  } from 'react-router-dom';
import PageLoader from "../components/PageLoader";
import ApiService from '../services/ApiService';
import { toast } from "react-toastify";
import Paginator from "../components/Paginator"; 


const RealEstate = () => {
  const { t } = useTranslation();
  const [isPageLoader, setPageLoader] = useState(false);
  const [auctions, setAuctions] = useState([]);
  const [pagination, setPagination] = useState({});
  const app_lang=localStorage.getItem('lang') || 'en';
  const [filterData, setFilterData] = useState({
    "search": "",
    "page": 1
  });


  useEffect(() => {
    window.scrollTo(0, 0);
    getAuctions();
  }, [filterData]);

  const getAuctions = async () => {
    try {
      setPageLoader(true);
      const response = await ApiService.request({
        method: 'GET',
        url: `getAllAuctions`, // Replace with your API endpoint
        params:filterData
      });
      const data = response.data;
      if (data.status) {
        setAuctions(data.data);
        setPagination(data.pagination);
      } else {
        toast.error(data.message);
      }
      setPageLoader(false);
    } catch (error) {
      setPageLoader(false);
    }
  };

  const handlePageChange = (event) => {
      setFilterData(prev => ({ ...prev, page: event }));
  };

  return (
    <div>
      {isPageLoader ? (
        <PageLoader />
      ):(
        <div>
          {/* Top Bar */}
          <Topbar />

          {/* Navbar */}
          <Navbar />

          {/* Header */}
          <div className="estate-header">
            <div className="header-txt text-white">
              <p>
                  <NavLink to="/" className="nav-link d-inline">  {t('home')} </NavLink>  
                  <i className={`bi ${app_lang === 'en' ? 'bi-chevron-right' : 'bi-chevron-left'}`}></i> {t('auctions')}
              </p>
            </div>
          </div>
          <div className="background-color mb-5">
            <div className="container form-main-head mt-5 rounded p-4 bg-white">
              <h3 className="fw-normal">{t("auctions")}</h3>
            <div className="table-responsive">
              <table className="table  text-center mt-5">
              <thead className="bg-dark" >
                <tr >
                  <th scope="col">{t("img")}</th>
                  <th scope="col">{t("pro_summary")}</th>
                  <th scope="col">{t("curr_price")}</th>
                  <th scope="col">{t("bids_placed")}</th>
                  <th scope="col">{t("ending")}</th>
                  <th scope="col">{t("bids_now")}</th>
                </tr>
              </thead>
              <tbody>
              {auctions.map((auction) => (
                <tr>
                  <td><img src={auction.media_files[0].file} alt="Product" className="auction-img" /></td>
                  <td>{auction.title} </td>
                  <td>{t('currency')} {auction.open_price}</td>
                  <td>{auction.bids_count}</td>
                  <td>{auction.rem_days} {t("days")}</td>
                  <td>
                    <NavLink to={'/auction-details/'+auction.id} className="btn btn-base">{t("bid_now")}</NavLink>
                  </td>
                </tr>
              ))}
              </tbody>
              </table>
            </div>
            {pagination.total_pages > 1 && (
                  <Paginator
                      page={pagination.current_page}
                      rows={pagination.per_page}
                      totalRecords={pagination.total_records}
                      onPageChange={handlePageChange}
                  />
              )}
            </div>
          </div>

          {/* Footer */}
          <Footer />
        </div>
      )}
    </div>
  );
};

export default RealEstate;
