import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {  NavLink } from "react-router-dom";
// import LanguageSwitcher from "../components/LanguageSwitcher";
import "../App.css";
import Navbar from "../components/navBar/NavBar";
import Footer from "../components/Footer/Footer";
import Header from "../components/header/Header";
import "../components/header/header.css";
import Topbar from "../components/Top Bar/Topbar";
import CountUp from "react-countup";
import "react-range-slider-input/dist/style.css";
import AOS from "aos";
import "aos/dist/aos.css";
import PageLoader from "../components/PageLoader";
import counterBG from "../assets/img/counter-bg.png";
import ApiService from '../services/ApiService';
import PropertyCard from "../components/PropertyCard";


const Home = () => {
  const { t } = useTranslation();

const [properties, setProperties] = useState([]);
const [isPageLoader, setPageLoader] = useState(false);
const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({
      duration: 1000,
      once: false,
    });
    getProperties();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (observer && sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [isPageLoader]);


  const getProperties = async () => {
    try {
      setPageLoader(true);
      const response = await ApiService.request({
        method: 'GET',
        url: `getFeaturedProperties`, // Replace with your API endpoint
      });
      const data = response.data;
      if (data.status) {
        setProperties(data.data);
      } else {
        toast.error(data.message);
      }
      setPageLoader(false);
    } catch (error) {
      console.error(error);
      setPageLoader(false);

    }
  };


  return (
    <div className="">
    {isPageLoader ? (
      <PageLoader />
    ):(

      <div className="main-home">
        
        {/* Top Section */}
        <Topbar />

        {/* Navbar */}
        <Navbar />

        {/* Header */}
        <Header />

        {/* Section 2 --Achievements*/}
        <div
          ref={sectionRef}
          className="container-fluid achievements-section"
          style={{
            backgroundImage: `url(${counterBG})`,
            backgroundSize: "contain",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            height: "85vh",
          }}
        >
          <div className="text-center mb-5" data-aos="fade-up">
            <h2 className="text-white">{t("achievement")}</h2>
            <p className="text-white">{t("started")}</p>
          </div>
          <div className="row text-center" data-aos="fade-up">
            <div className="col-md-2"></div>

            <div className="col-md-2 achievement col-6">
              <div className="achievement-circle">
                <h3>{isVisible && <CountUp end={80} duration={4} />}+</h3>
                <p>{t("real_estate")}</p>
              </div>
            </div>

            <div className="col-md-2 achievement col-6">
              <div className="achievement-circle">
                <h3>{isVisible && <CountUp end={27} duration={4} />}+</h3>
                <p>{t("w_hours")}</p>
              </div>
            </div>

            <div className="col-md-2 achievement col-6">
              <div className="achievement-circle">
                <h3>{isVisible && <CountUp end={239} duration={4} />}+</h3>
                <p>{t("s_customers")}</p>
              </div>
            </div>

            <div className="col-md-2 achievement col-6">
              <div className="achievement-circle">
                <h3>{isVisible && <CountUp end={50} duration={4} />}+</h3>
                <p>{t("f_ads")}</p>
              </div>
            </div>

            <div className="col-md-2"></div>
          </div>
        </div>

        {/* Section 3 --Latest Featured Real Estate */}
        <div className="container-fluid">
          <div className="featured-heading">
            <h4 data-aos="fade-up">{t("estate2")}</h4>
            <h1 data-aos="fade-up">{t("latest_estate")}</h1>

            <div className="container" data-aos="fade-up">
              <div className="row">
                {properties.map((property) => (
                  <div className="col-md-4" key={property.id}>
                    <PropertyCard property={property} />
                  </div>
                ))}
              
              </div>
            </div>

            <div className="featured-btn" data-aos="zoom-in">
              <NavLink to="/properties"> <button>{t("properties")}</button> </NavLink>
            </div>
          </div>
        </div>

        {/* Section 4 --Choose your property */}
        <div className="container mb-5">
          <div className="row">
            <div className="property-heading text-center mb-5" data-aos="fade-up">
              <h1>{t("favorite_city")}</h1>
            </div>
            <div className="col-md-4" data-aos="flip-up">
              <div className="responsive property">
                <div className="property-img">
                  <div className="text-overlay">
                    <h4 className="text-white">{t("Riyadh")}</h4>
                  </div>
                  <div className="overlay"></div>
                </div>
              </div>
            </div>
            <div className="col-md-4" data-aos="flip-up">
              <div className="responsive property">
                <div className="property-img2">
                  <div className="text-overlay">
                    <h4 className="text-white">{t("Jeddah")}</h4>
                  </div>
                  <div className="overlay"></div>
                </div>
              </div>
            </div>
            <div className="col-md-4" data-aos="flip-up">
              <div className="responsive property">
                <div className="property-img3">
                  <div className="text-overlay">
                    <h4 className="text-white">{t("Dammam")}</h4>
                  </div>
                  <div className="overlay"></div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3" data-aos="flip-up">
              <div className="responsive property">
                <div className="property-img4">
                  <div className="text-overlay">
                    <h4 className="text-white">{t("Taif")}</h4>
                  </div>
                  <div className="overlay"></div>
                </div>
              </div>
            </div>
            <div className="col-md-8 mt-3" data-aos="flip-up">
              <div className="responsive property">
                <div className="property-img5">
                  <div className="text-overlay">
                    <h4 className="text-white">{t("Makkah")}</h4>
                  </div>
                  <div className="overlay"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )}
    </div>
  );
};

export default Home;
