import React, { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { NavLink, useLocation , useNavigate  } from 'react-router-dom';
import "../App.css";
import Navbar from "../components/navBar/NavBar";
import Footer from "../components/Footer/Footer";
import "../components/header/header.css";
import Topbar from "../components/Top Bar/Topbar";
import "react-range-slider-input/dist/style.css";
import PageLoader from "../components/PageLoader";
import ApiService from '../services/ApiService';
import PropertyCard from "../components/PropertyCard";
import Paginator from "../components/Paginator"; 
import FilterSideBar from "../components/FilterSideBar/FilterSideBar";


const RealEstate = () => {
  const { t } = useTranslation();
  const app_lang=localStorage.getItem('lang') || 'en';
  const [isPageLoader, setPageLoader] = useState(false);
  const [isClear, setClear] = useState(0);
  const [properties, setProperties] = useState([]);
  const [pagination, setPagination] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const data = queryParams.get('filterData');
  const defaultFilterData = data ? JSON.parse(decodeURIComponent(data)) : {
    search: "",
    type: "",
    category_id: "",
    features: [],
    bedrooms: [0, 100],
    bathrooms: [0, 100],
    area: [0, 1000],
    price: [10, 500000000],
    sort_by: "",
    page:1,
  };

  const [filterData, setFilterData] = useState(defaultFilterData);

  useEffect(() => {
    window.scrollTo(0, 0);
    getProperties();
  }, [filterData.sort_by, isClear]);

  const getProperties = async () => {
    try {
      setPageLoader(true);
      const response = await ApiService.request({
        method: 'POST',
        url: `filterProperties`, // Replace with your API endpoint
        data:filterData
      });
      const data = response.data;
      if (data.status) {
        setProperties(data.data);
        setPagination(data.pagination);
      } else {
        toast.error(data.message);
      }
      setPageLoader(false);
    } catch (error) {
      setPageLoader(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleRangeChange = (name) => (value) => {
    setFilterData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
    }));
  };
  const handleFeatureChange = (e) => {
    const { value } = e.target;
    setFilterData((prevFormData) => {
      const features = prevFormData.features.includes(value)
        ? prevFormData.features.filter((feature) => feature !== value) // remove if already exists
        : [...prevFormData.features, value]; // add if not exists
      return { ...prevFormData, features };
    });
  };
  const handlePageChange = (event) => {
    setFilterData(prev => ({ ...prev, page: event }));
  };
  

  const resetFilter =  () => {
    setFilterData(defaultFilterData); // Reset to default state
    navigate(location.pathname, { replace: true }); 
    setClear(isClear+1);
    getProperties();
  };

  return (
    <div className="main-property">
      {isPageLoader ? (
        <PageLoader />
      ):(
      <div>
        {/* Top Bar */}
        <Topbar />

        {/* Navbar */}
        <Navbar />

        {/* Header */}
        <div className="estate-header">
          <div className="header-txt text-white">
            <p>
            <NavLink to="/" className="nav-link d-inline">  {t('home')} </NavLink>  
                  <i className={`bi ${app_lang === 'en' ? 'bi-chevron-right' : 'bi-chevron-left'}`}></i> {t('real_estate')}
            </p>
          </div>
        </div>

        <div className="container mb-5">
          <div className="row">
            <div className="col-md-4">
              <FilterSideBar filterData={filterData}  handleChange={handleChange} handleRangeChange={handleRangeChange} handleFeatureChange={handleFeatureChange}
               onSearch={getProperties} resetFilter={resetFilter} />
            </div>
            <div className="col-md-8">
              <div className="d-flex align-items-center justify-content-between mt-5">
                <p className="mb-0">{t('showing_results')}: {pagination.total_records}</p>

                <div className="d-flex align-items-center">
                  <span className="me-2">{t("sort")}:</span>
                  <select name="sort_by" onChange={handleChange} className="form-select w-75" value={filterData.sort_by || ""}>
                    <option value="">{t('sort_by')}</option>
                    <option value="title-asc"> {t("a_z")}</option>
                    <option value="title-desc">{t("z_a")}</option>
                    <option value="created_at-asc">{t("oldest")}</option>
                    <option value="views-desc">{t("most")}</option>
                    <option value="price-asc">{t("l_h")}</option>
                    <option value="price-desc">{t("h_l")}</option>
                  </select>

                </div>
              </div>
              {/* Right bar - Properties list */}
              <div className="row">
                {properties.map((property) => (
                  <div className="col-md-6" key={property.id}>
                      <PropertyCard property={property} />
                  </div>
                ))}
              
              </div>

              {pagination.total_pages > 1 && (
                  <Paginator
                      page={pagination.current_page}
                      rows={pagination.per_page}
                      totalRecords={pagination.total_records}
                      onPageChange={handlePageChange}
                  />
              )}

              {properties.length < 1 &&
              
              <p className="bg-white border p-4 rounded text-danger"> No Property found</p>

              }

            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )}
    </div>
  );
};

export default RealEstate;
