import React, {useState,useEffect } from "react";
import Swal from 'sweetalert2';

import { Table, Dropdown, DropdownButton, InputGroup,
  Button, FormControl,
} from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ApiService from '../../../services/ApiService';
import { formatPrice } from '../../../utils/helper';
import { Link  } from 'react-router-dom';

const ChatBox = () => {
  const { t } = useTranslation();
  const app_lang=localStorage.getItem('lang') || 'en';
  const [properties, setProperties] = useState([]);
  const [pagination, setPagination] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [apiData, setApiData] = useState({
      search: "",
      daterange: "",
      page: 0
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getProperties();
  }, [apiData.page, apiData.search, apiData.daterange]);

  const getProperties = async () => {
    try {
      setIsLoader(true);
      const response = await ApiService.request({
        method: 'GET',
        url: `properties`, // Replace with your API endpoint
        params: apiData
      });
      const data = response.data;
      if (data.status) {
        setProperties(data.data);
        setPagination(data.pagination);
      } else {
        toast.error(data.message);
      }
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
    }
  };
  const delData = async (id) => {
    const result = await Swal.fire({
        title: t('are_you_sure'),
        text: t('not_able_revert'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonColor: '#9E9B9B',
        cancelButtonText: t('no'),
    });

    if (result.isConfirmed) {
        const response = await ApiService.request({
            method: "DELETE",
            url: `properties/${id}`, // Replace with your API endpoint
        });
        const data = response.data;
        if (data.status) {
            getProperties();
            toast.success(data.message);
        } else {
            toast.error(data.message);
        }
    }
};



  return (
    <div>
      <div className="card" id="chat3" style={{borderRadius: 15+ 'px'}} >
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 col-lg-5 col-xl-4 mb-4 mb-md-0 pe-0 border-end">
                Chat Box
            </div>
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default ChatBox;
