import React, {  useEffect } from "react";

import Topbar from "../components/Top Bar/Topbar";
import Footer from "../components/Footer/Footer";
import CustomNavbar from "../components/navBar/NavBar";
import { useTranslation } from "react-i18next";
import { NavLink  } from 'react-router-dom';


const TermsConditions = () => {
  const { t } = useTranslation();
  const app_lang=localStorage.getItem('lang') || 'en';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Topbar />
      <CustomNavbar />
      
      <div className="estate-header">
        <div className="header-txt text-white">
          <p>
              <NavLink to="/" className="nav-link d-inline">  {t('home')} </NavLink>  
              <i className={`bi ${app_lang === 'en' ? 'bi-chevron-right' : 'bi-chevron-left'}`}></i> {t('platform_terms')}
          </p>
        
        </div>
      </div>

      <div className="container mb-5">
        <div className="bg-white mt-5 p-5">
          <h4 className="fw-semibold"> {t('platform_terms')}</h4>

            {app_lang === 'en'? (
              <div className="main-en">
                <p>
                    Dear visitors and valued clients, you can reach us through social media, our contact form page, mobile, or WhatsApp at +966552668383.
                </p>

                <h5 className="fw-normal">Short Version:</h5>
                <ul>
                    <li> Any use of the website by the advertiser implies full acceptance of the current terms.</li>
                    <li> This website cannot be held liable for any negligence due to circumstances beyond its control.</li>
                    <li> This website reserves the right to modify all or part of the terms and conditions at any time.</li>
                    <li> Users should regularly review this page to stay informed of any changes.</li>
                    <li> Any complaints should be directed to this website's customer service at +966552668383.</li>
                </ul>

                <h4 className="fw-normal">Definitions:</h4>
                <p>Each of the terms below has the following meanings in the terms of sale for the aqare.sa service (hereafter referred to as "the Terms"):</p>
                <ul>
                    <li><strong>Ad:</strong> Refers to all elements and data (visual, text, audio, images, graphics) provided by the sponsor under their sole responsibility, with the purpose of buying, renting, or selling a product or service and broadcasting it on the website and mobile site.</li>
                    <li><strong>Advertiser:</strong> Refers to any natural or legal person, or entity, based in Saudi Arabia, who owns an account and has posted an ad through it on the website. The advertiser must be logged into their personal account to deposit and/or manage their listings. The first deposit automatically creates a personal account for the advertiser.</li>
                    <li><strong>Personal Account:</strong> Refers to the free space that every advertiser must create and connect to for publishing, managing, and displaying their listings on the website.</li>
                    <li><strong>aqare.sa:</strong> Refers to the company that publishes and manages the website and mobile site <a href="https://aqare.sa">https://aqare.sa</a>, registered in the commercial and company registry in Riyadh under number 1010313946, with its headquarters located at 8722 Ubadah Bin As-Samit, Al-Rawdah, 4299, Riyadh 13211.</li>
                    <li><strong>Customer Service:</strong> “aqare.sa” is the department where the advertiser can obtain more information. Customer service can be contacted via email by clicking the link on the website and mobile site.</li>
                    <li><strong>aqare.sa Service:</strong> Refers to the services available to users and advertisers on the website and mobile site.</li>
                    <li><strong>Website:</strong> Refers to the site operated by aqare.sa, mainly accessed at <a href="https://aqare.sa">https://aqare.sa</a>, which allows users and advertisers to access the service online through aqare.sa.</li>
                    <li><strong>Mobile Site:</strong> Refers to the mobile site operated by aqare.sa, accessible to users and advertisers through the mobile service aqare.sa.</li>
                    <li><strong>User:</strong> Any visitor with access to the aqare.sa service via the website, mobile site, or any other related service available through different media.</li>
                </ul>

                <h4 className="fw-normal">Subject of this Content:</h4>
                <p>These terms and conditions of use set forth the contractual terms applicable to any subscription by an advertiser connected to their personal account on the website and mobile site.</p>

                <h4  className="fw-normal">Acceptance of Terms of Use:</h4>
                <p>Any use of the website by an advertiser constitutes full acceptance of the current terms.</p>

                <h4  className="fw-normal">Liability of this Platform:</h4>
                <p>aqare.sa cannot be held liable for non-performance or improper performance due to an event beyond its control, whether caused by the advertiser, the ad, or a force majeure event.</p>

                <h4  className="fw-normal">Amendment of Terms and Conditions:</h4>
                <p>aqare.sa reserves the right to modify all or part of the terms and conditions at any time.</p>
                <p>Advertisers are advised to regularly check the terms to stay informed of any changes.</p>

                <h4  className="fw-normal">Other Provisions:</h4>
                <p>If any part of the terms is illegal, invalid, or unenforceable for any reason, that clause will be considered null without affecting the validity of the remaining clauses, which will continue in effect between the advertisers and aqare.sa.</p>
                <p>Any complaints should be directed to aqare.sa customer service.</p>

                <p>
                    Dear visitors and valued clients, you can reach us through social media, our contact form page, mobile, or WhatsApp at +966552668383.
                </p>

              </div>
            ):(
              <div className="main-ar">
                <p>
                  زوارنا الكرام وعملائنا الأعزاء، يمكنكم الوصول إلينا عبر وسائل التواصل الاجتماعي، أو صفحة نموذج الاتصال، أو الهاتف المحمول، أو الواتساب على الرقم +966552668383.
                </p>

                <h5 className="fw-normal">نسخة مختصرة:</h5>
                <ul>
                  <li> أي استخدام للموقع من قبل المعلن يعني قبولًا كاملًا للشروط الحالية.</li>
                  <li> لا يمكن تحميل هذا الموقع المسؤولية عن أي إهمال ناتج عن ظروف خارجة عن إرادته.</li>
                  <li> يحتفظ هذا الموقع بالحق في تعديل كل أو جزء من الشروط والأحكام في أي وقت.</li>
                  <li> يجب على المستخدمين مراجعة هذه الصفحة بانتظام للبقاء على اطلاع على أي تغييرات.</li>
                  <li> يجب توجيه أي شكاوى إلى خدمة عملاء الموقع على الرقم +966552668383.</li>
                </ul>

                <h4 className="fw-normal">التعريفات:</h4>
                <p>كل مصطلح أدناه له المعنى التالي في شروط البيع لخدمة aqare.sa (التي يشار إليها لاحقًا بـ "الشروط"):</p>
                <ul>
                  <li><strong>الإعلان:</strong> يشير إلى جميع العناصر والبيانات (مرئية، نصية، صوتية، صور، رسومات) المقدمة من قبل الراعي تحت مسؤوليته الكاملة بهدف شراء أو تأجير أو بيع منتج أو خدمة وبثه على الموقع والموقع المتنقل.</li>
                  <li><strong>المعلن:</strong> يشير إلى أي شخص طبيعي أو اعتباري، أو كيان مقيم في المملكة العربية السعودية، الذي يملك حسابًا وقد قام بنشر إعلان عبره على الموقع. يجب أن يكون المعلن مسجلًا في حسابه الشخصي لإيداع وإدارة قوائمه. يؤدي الإيداع الأول تلقائيًا إلى إنشاء حساب شخصي للمعلن.</li>
                  <li><strong>الحساب الشخصي:</strong> يشير إلى المساحة المجانية التي يجب على كل معلن إنشاؤها وتسجيل الدخول إليها لنشر وإدارة وعرض قوائمه على الموقع.</li>
                  <li><strong>aqare.sa:</strong> تشير إلى الشركة التي تنشر وتدير الموقع والموقع المتنقل <a href="https://aqare.sa">https://aqare.sa</a>، والمسجلة في السجل التجاري والشركات في الرياض برقم 1010313946، ومقرها الرئيسي يقع في 8722 عبادة بن الصامت، الروضة، 4299، الرياض 13211.</li>
                  <li><strong>خدمة العملاء:</strong> "aqare.sa" هو القسم الذي يمكن للمعلن من خلاله الحصول على مزيد من المعلومات. يمكن الاتصال بخدمة العملاء عبر البريد الإلكتروني من خلال النقر على الرابط الموجود في الموقع والموقع المتنقل.</li>
                  <li><strong>خدمة aqare.sa:</strong> تشير إلى الخدمات المتاحة للمستخدمين والمعلنين على الموقع والموقع المتنقل.</li>
                  <li><strong>الموقع:</strong> يشير إلى الموقع الذي تديره aqare.sa، ويتم الوصول إليه بشكل رئيسي عبر <a href="https://aqare.sa">https://aqare.sa</a>، والذي يسمح للمستخدمين والمعلنين بالوصول إلى الخدمة عبر الإنترنت من خلال aqare.sa.</li>
                  <li><strong>الموقع المتنقل:</strong> يشير إلى الموقع المتنقل الذي تديره aqare.sa، والذي يمكن الوصول إليه من قبل المستخدمين والمعلنين من خلال خدمة aqare.sa المتنقلة.</li>
                  <li><strong>المستخدم:</strong> أي زائر لديه إمكانية الوصول إلى خدمة aqare.sa عبر الموقع أو الموقع المتنقل أو أي خدمة أخرى متاحة من خلال وسائل الإعلام المختلفة.</li>
                </ul>

                <h4 className="fw-normal">موضوع هذه المحتوى:</h4>
                <p>تحدد هذه الشروط والأحكام الاستخدام التعاقدي المطبق على أي اشتراك من قبل المعلن المتصل بحسابه الشخصي على الموقع والموقع المتنقل.</p>

                <h4 className="fw-normal">قبول شروط الاستخدام:</h4>
                <p>أي استخدام للموقع من قبل المعلن يشكل قبولًا كاملًا للشروط الحالية.</p>

                <h4 className="fw-normal">مسؤولية هذه المنصة:</h4>
                <p>لا يمكن تحميل aqare.sa المسؤولية عن عدم الأداء أو الأداء غير الصحيح بسبب حدث خارج عن إرادتها، سواء كان سببه المعلن أو الإعلان أو حدث قوة قاهرة.</p>

                <h4 className="fw-normal">تعديل الشروط والأحكام:</h4>
                <p>تحتفظ aqare.sa بالحق في تعديل كل أو جزء من الشروط والأحكام في أي وقت.</p>
                <p>يُنصح المعلنون بمراجعة الشروط بانتظام للبقاء على اطلاع على أي تغييرات.</p>

                <h4 className="fw-normal">أحكام أخرى:</h4>
                <p>إذا كان أي جزء من الشروط غير قانوني أو غير صالح أو غير قابل للتنفيذ لأي سبب، فسيعتبر هذا البند باطلًا دون التأثير على صلاحية البنود المتبقية التي ستظل سارية بين المعلنين وaqare.sa.</p>
                <p>يجب توجيه أي شكاوى إلى خدمة عملاء aqare.sa.</p>

                <p>
                  زوارنا الكرام وعملائنا الأعزاء، يمكنكم الوصول إلينا عبر وسائل التواصل الاجتماعي، أو صفحة نموذج الاتصال، أو الهاتف المحمول، أو الواتساب على الرقم +966552668383.
                </p>

              </div>
            )}

        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsConditions;