// UserDashboard.js
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Card, Table } from "react-bootstrap";
import ApiService from '../../services/ApiService';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const DashboardPage = () => {
  const user= JSON.parse(localStorage.getItem('user'));
  const { t } = useTranslation();
  const [stats, setStats] = useState([]);
  const [user_package, setUserPackage] = useState(null);

  
  useEffect(() => {
    window.scrollTo(0, 0);
    getDashboard();
  }, []);

  const getDashboard = async () => {
    try {
      // setIsLoader(true);
      const response = await ApiService.request({
        method: 'GET',
        url: `getDashboard`, // Replace with your API endpoint
      });
      const data = response.data;
      if (data.status) {
        setStats(data.data.stats);
        setUserPackage(data.data.package);
      } else {
        toast.error(data.message);
      }
      // setIsLoader(false);
    } catch (error) {
      // setIsLoader(false);
    }
  };

  return (
    <div>
     
          <div className="dashboard rounded d-flex p-3">
            <div className="dashboard-head-img border">
              <img src={user.picture} alt="" />
            </div>
            <div className="img-txt">
              <h4 className="mx-3 mt-3">{user.first_name} {user.last_name}</h4>
              <h6 className="mx-3">
                {t('email')}:
                <span className="fw-lighter mx-2">
                  {user.email}
                </span>
              </h6>
            </div>
          </div>
          <div>
            <div className="sec-ii mt-3">
              <div className="sec-ii-icons d-flex flex-wrap justify-content-center">
                <div className="icon-i rounded col-12 col-md-3 col-lg-3 mb-3">
                  <i className="bi bi-card-list"></i>
                  <h5 className="mt-3 text-center">
                    <span className="fw-light mx-2">
                      {t('total_properties')}
                    </span>
                    {stats['total_properties']}
                  </h5>
                </div>

                <div className="icon-i icon-ii rounded col-12 col-md-3 col-lg-3 mb-3">
                  <i className="bi bi-megaphone-fill"></i>
                  <h5 className="mt-3 text-center">
                    <span className="fw-light mx-2">
                    {t('active_properties')}
                    </span>
                    {stats['active_properties']}
                  </h5>
                </div>

                <div className="icon-i icon-iii rounded col-12 col-md-3 col-lg-3 mb-3">
                  <i className="bi bi-hourglass-split"></i>
                  <h5 className="mt-3 text-center">
                    <span className="fw-light mx-2">
                    {t('pending_properties')}
                    </span>
                    {stats['pending_properties']}
                  </h5>
                </div>

                <div className="icon-i icon-iv rounded col-12 col-md-3 col-lg-3 mb-3">
                  <i className="bi bi-exclamation-triangle"></i>
                  <h5 className="mt-3 text-center">
                    <span className="fw-light mx-2">
                    {t('expired_properties')}
                    </span>
                    0
                  </h5>
                </div>
              </div>
            </div>
          </div>
         
          <Card className="p-2 mt-3 card-main">
            <Card.Body>
              <Card.Title>{t('membership_report')}</Card.Title>
              {user_package ?(
                <div className="mb-3">
                  <p className="fw-lighter">
                    <span className="fw-light">{t('status')}:  {user_package.status}</span> 
                  </p>
                  <p className="fw-lighter">
                    <span className="fw-light">{t('validity')}:</span> {t('until')}: {user_package.expiry_date}
                  </p>
                  <p className="fw-lighter">
                    <span className="fw-light">{t('remaining_ads')}:</span> {user_package.package?.no_ads - stats.total_properties}
                  </p>
                  <p className="fw-lighter">
                    <span className="fw-light">{t('posted_ads')}:</span> {stats.total_properties}
                  </p>
                </div>
              ):(
                  <div className="mb-3 alert alert-danger fs-6 fw-semibold">You don't have purchased any subscription</div>
              )}
              
              {/* <Table bordered responsive>
                <thead className="thead">
                  <tr>
                    <th>Promotions</th>
                    <th>Remaining ads</th>
                    <th>Validation Duration (# Days)</th>
                  </tr>
                </thead>
                <tbody className="tbody">
                  <tr>
                    <td>Featured</td>
                    <td>25</td>
                    <td>30</td>
                  </tr>
                  <tr>
                    <td>Best Seller</td>
                    <td>29</td>
                    <td>30</td>
                  </tr>
                  <tr>
                    <td>Lift up</td>
                    <td>28</td>
                    <td>30</td>
                  </tr>
                </tbody>
              </Table> */}
              <p>
              {t('buy_membership_from')} <NavLink to="/packages">{t('here')}</NavLink>.
              </p>
            </Card.Body>
          </Card>
              
    </div>
  );
};

export default DashboardPage;
