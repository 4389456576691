import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDrn8cCRRK-lTdCulxjGtyA2OIgSHaRPzE",
  authDomain: "aqare-digits.firebaseapp.com",
  projectId: "aqare-digits",
  storageBucket: "aqare-digits.firebasestorage.app",
  messagingSenderId: "450571460122",
  appId: "1:450571460122:web:135684d09d53e42ef7bba3",
  measurementId: "G-J0BPNW0GGZ"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);