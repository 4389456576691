import React, { useState , useEffect} from "react";
import { useTranslation } from "react-i18next";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import "./filterSideBar.css";
import { toast } from "react-toastify";
import { formatPrice } from '../../utils/helper';
import ApiService from '../../services/ApiService';

const FilterSideBar = ({filterData, handleChange, handleRangeChange, handleFeatureChange, onSearch, resetFilter }) => {
  const { t } = useTranslation();
 
  const [categories, setCategories] = useState([]);
  const [features, setFeatures] = useState([]);
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const toggleCheckboxes = () => {
    setShowCheckboxes((prev) => !prev);
  };

  
  useEffect(() => {
    
    getDropdownData();
  }, []);

  const getDropdownData = async () => {
    try {
      const response = await ApiService.request({
        method: 'GET',
        url: `getDropdownData?type=listing`, // Replace with your API endpoint
      });
      const data = response.data;
      if (data.status) {
        setCategories(data.data.categories);
        setFeatures(data.data.features);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

//   const handleRangeChange = (name) => (event) => {
//    console.log(name,  event);
// };


  return (
    <>
      <div className="sidebar my-5 shadow-sm p-3 mb-5 bg-body-tertiary rounded">
        <div className="sidebar-search ">
          <h4>{t("ad_search")}</h4>
          <input
            name="search"
            value={filterData.search}
            type="text"
            placeholder={t('looking_for')}
            className="mt-3"
            onChange={handleChange}
          />
        </div>
        <div className="categories-options mb-3">
          <select name="type" className="mt-3"   onChange={handleChange}  value={filterData.type}>
            <option value="Property Type" selected>
            {t("type")}
            </option>
            <option value="Sell">{t("sell")}</option>
            <option value="Rent">{t("rent")}</option>
          </select>
        </div>
        <div className="categories-options mb-3">
          <select name="category_id" className="mt-3" onChange={handleChange} value={filterData.category_id}>
            <option value="All Categories" selected>
            {t("categories")}
            </option>
            {categories.map((category, index) => (
              <option value={category.id} key={index}>{category.name}</option>
            ))}
            
          </select>
        </div>

        <div className="filterBox">
          <h6>{t("filter_search")}</h6>
          <RangeSlider  
            onInput={handleRangeChange('price')}
            value={filterData.price}
            min={49}
            max={500000}
            step={1}
          />
          <div className="d-flex pt-2 pb-2 priceRange">
            <span className="mx-2">
            {t("from")}:
              <strong className="text-success">{formatPrice(filterData.price[0])}</strong>
            </span>
            <div className="ml-auto">
            {t("to")}:
              <strong className="text-success">{formatPrice(filterData.price[1])}</strong>
            </div>
          </div>
        </div>

        <div
          className="filter my-4 d-flex align-items-center"
          onClick={toggleCheckboxes}
        >
          <i className="bi bi-filter-circle"></i>
          <div className="filter-txt">
            <p>{t("amenities")}</p>
          </div>
        </div>

        {showCheckboxes && (
          <div>
            <div className="filter-checkboxes">
              {features.map((feature, index) => (
                <label key={index}>
                  <input type="checkbox" className="custom-checkbox" value={feature.name} name='features'
                   checked={filterData.features.includes(feature.name)}  onChange={handleFeatureChange}
                    />
                  {feature.name}
                </label>
              ))}
            
            </div>

            <div className="filterBox mt-4">
              <h6>{t("bedrooms")}</h6>
              <RangeSlider
                onInput={handleRangeChange('bedrooms')}
                value={filterData.bedrooms}
                min={0}
                max={100}
                step={1}
              />
              <div className="d-flex pt-2 pb-2 priceRange">
                <span>
                  <strong className="text-success">{filterData.bedrooms[0]}</strong>
                </span>
                <div className="ml-auto">
                  - <strong className="text-success">{filterData.bedrooms[1]}</strong>
                </div>
              </div>
            </div>

            <div className="filterBox">
              <h6>{t("the_bathroom")}</h6>
              <RangeSlider
                onInput={handleRangeChange('bathrooms')}
                value={filterData.bathrooms}
                min={0}
                max={100}
                step={1}
              />
              <div className="d-flex pt-2 pb-2 priceRange">
                <span>
                  <strong className="text-success">{filterData.bathrooms[0]}</strong>
                </span>
                <div className="ml-auto">
                  - <strong className="text-success">{filterData.bathrooms[1]}</strong>
                </div>
              </div>
            </div>

            <div className="filterBox">
              <h6>{t("area")}</h6>
              <RangeSlider
                onInput={handleRangeChange('area')}
                value={filterData.area}
                min={0}
                max={1000}
                step={5}
              />
              <div className="d-flex pt-2 pb-2 priceRange">
                <span>
                  <strong className="text-success">{filterData.area[0]}</strong>
                </span>
                <div className="ml-auto">
                  - <strong className="text-success">{filterData.area[1]}</strong>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="sidebarBtn mt-3">
          <button onClick={onSearch}>{t("f_property")}</button>
          <button onClick={resetFilter}>
            <i className="bi bi-arrow-repeat"  ></i> {t("reset")}
          </button>
        </div>
      </div>
      
    </>
  );
};

export default FilterSideBar;
