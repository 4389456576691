
import React, { useState, useEffect } from "react";
import Topbar from "../components/Top Bar/Topbar";
import Footer from "../components/Footer/Footer";
import CustomNavbar from "../components/navBar/NavBar";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate  } from 'react-router-dom';
import ApiService from '../services/ApiService';
import { toast } from "react-toastify";


const NafathAuth = () => {
  const { t } = useTranslation();
  const app_lang=localStorage.getItem('lang') || 'en';

  const [apiResponse, setApiResponse] = useState(null);
  const [nationalId, setNationalId] = useState('');
  const [isLoader, setIsLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const handleSubmit = async (e) => {
    setIsLoader(true);
    e.preventDefault();
    try {
      const response = await ApiService.request({
        method: 'POST',
        url: `checkNafath`, // Replace with your API endpoint
        data: {nationalId:nationalId}
      });
      const data = response.data;
      setApiResponse(data);
      if (data.status) {
        toast.success(data.message);
        checkNafathStatus(data); 
      } else {
        toast.error(data.message);
      }
      setIsLoader(false);
    } catch (error) {
      toast.error(error);
      setIsLoader(false);

    }
  };

  const checkNafathStatus = async (apiData) => {
    let formData = { ...apiData.data };
    formData.nationalId = nationalId;
    console.log(formData);
    try {
      const response = await ApiService.request({
        method: 'POST',
        url: `checkNafathStatus`, // Replace with your API endpoint
        data: formData
      });
      const data = response.data;
      if (data.status && data.data.status === "COMPLETED") {
        navigate('/ad-type');
      }
      else if (data.status && data.data.status === "WAITING") {
          // Call checkNafathStatus again after a 1-second delay if status is "Waiting"
          setTimeout(() => checkNafathStatus(apiData), 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Topbar />
      <CustomNavbar />
      
      <div className="estate-header">
        <div className="header-txt text-white">
          <p>
              <NavLink to="/" className="nav-link d-inline">  {t('home')} </NavLink>  
              <i className={`bi ${app_lang === 'en' ? 'bi-chevron-right' : 'bi-chevron-left'}`}></i> {t('check_nafath')} 
          </p>
        
        </div>
      </div>

      <div className="container mb-5">
          <div className="bg-white p-5 mt-5 rounded" style={{ minHeight: '350px' }}>
            <h2 className="fw-normal"> {t('check_nafath')}  </h2>
            <h3 className="text-center mt-5"> {t('nafath_desc')}</h3>
            <div className="row mt-4">
              <div className="col-md-6 col-12 offset-md-3">
                <input type="number"  value={nationalId}  onChange={(e) => setNationalId(e.target.value)}
                className="form-control" placeholder={t('enter')+" "+t('national_id')}  />
                {apiResponse?.status === false &&
                <p className="alert alert-danger mt-3">
                 <i className="bi bi-x-circle-fill "></i> {t('nafath_error')} 
                </p>
                }
                {apiResponse?.status === true &&
                <div>
                  <p className="alert alert-warning mt-3">
                  <i className="bi bi-patch-exclamation-fill "></i> {t('nafath_success')}
                  
                  </p>
                  <p className=" text-center">
                    <h3 className="alert alert-success  fw-normal rounded p-3 d-inline-block">
                    {t('verification_number')}<br />
                      {apiResponse?.data.random}
                    </h3>
                  </p>
                </div>
                }
              </div>
              <div className="col-md-3 col-8">
                <button type="button" onClick={handleSubmit}  disabled={isLoader}  className="btn btn-base text-white">
                {t('verify_identity')}
                  {isLoader && <span className="spinner-grow spinner-grow-sm text-white"></span>}
                </button>
              </div>
            </div>
          </div>
      </div>
      <Footer />
    </>
  );
};

export default NafathAuth;