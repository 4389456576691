import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import mapMarker from "../assets/img/map-marker.png";
import {  NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const icon = new L.Icon({
  iconUrl: mapMarker,
  iconSize: [41, 41],
  iconAnchor: [12, 41]
});

const MapWithMarkers = ({ properties, currency }) => (
  
  
  <MapContainer center={[properties[0]?.lat ?? 21.5565224,  properties[0]?.lng ?? 39.1851878]} zoom={11} style={{ height: '100vh', width: '100%',zIndex:0 }}>
    <TileLayer
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
    />
    {properties.map((property, index) => (
      <Marker
        key={index}
        position={[property.lat, property.lng]}
        icon={icon}
      >
        <Popup>
          <div>
            <NavLink   className="text-dark nav-link fw-bold"   to={`/property/`+property.slug}>
              <p>{property.title}</p>
            </NavLink>
            <p> {currency} {property.price}</p>
            <NavLink   className=" nav-link fw-bold"   to={`/property/`+property.slug}>View Details</NavLink>
          </div>
        </Popup>
      </Marker>
    ))}
  </MapContainer>
);

export default MapWithMarkers;
